import { StyleProp, View, ViewStyle } from 'react-native';

import styles from '../styles';
import Bubble from './bubbles/Bubble';
import Image from './Image';
import Text from './Text';
import { localImageMap, primitive } from '../constants';
import BlackBubbleHeader from './BlackBubbleHeader';

const Divider = ({ style }: { style: StyleProp<ViewStyle> }) => (
  <View
    style={[{ width: '100%', height: 1, backgroundColor: '#DEDEDE' }, style]}
  />
);

export default function OrderConfirmation({
  price,
  product,
  paymentMethod,
  address,
  localImageName,
  style,
}: {
  price: string;
  product: string;
  paymentMethod: string;
  address: string;
  localImageName: string;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <Bubble
      style={[
        styles.colStack,
        {
          width: 252,
          borderRadius: 16,
          backgroundColor: '#F2F2F2',
        },
        style,
      ]}
    >
      <BlackBubbleHeader
        iconSource={require('../assets/ic-receipt.png')}
        title="Order confirmation"
      />

      <View style={{ paddingVertical: 16, paddingHorizontal: 16 }}>
        <View
          style={[
            styles.rowStack,
            { alignItems: 'flex-start', marginBottom: 8 },
          ]}
        >
          <Image
            source={localImageMap[localImageName]}
            style={{
              width: 84,
              aspectRatio: 1,
              marginRight: 8,
              backgroundColor: 'white',
            }}
          />
          <Text style={[styles.textSmall, { fontWeight: '700', flex: 1 }]}>
            {product}
          </Text>
        </View>

        <View style={[styles.colStack, { marginBottom: 4 }]}>
          <Text style={[styles.textXSmall, { color: '#757575' }]}>
            Paid with
          </Text>
          <Text style={[styles.textSmall]}>{paymentMethod}</Text>
        </View>

        <View style={[styles.colStack]}>
          <Text style={[styles.textXSmall, { color: '#757575' }]}>Ship to</Text>
          <Text style={[styles.textSmall]}>{address}</Text>
        </View>

        <Divider style={{ marginVertical: 12 }} />
        <View
          style={[
            styles.rowStack,
            { justifyContent: 'space-between', alignItems: 'baseline' },
          ]}
        >
          <Text style={[styles.textSmall, { color: '#757575' }]}>Total</Text>
          <Text style={[styles.textMedium, { fontWeight: '700' }]}>
            {price}
          </Text>
        </View>
      </View>
    </Bubble>
  );
}
