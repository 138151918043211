import { isSameDay } from 'date-fns';
import { useLayoutEffect, useState } from 'react';

import { colors } from '../constants';
import styles from '../styles';
import { formatChannelUpdatedAt, getChannelUpdatedAt } from '../utils/common';
import Text from './Text';

export default function ChannelUpdatedAt({ channel, style, ...props }) {
  const updatedAt = getChannelUpdatedAt(channel);
  const [text, setText] = useState();

  useLayoutEffect(() => {
    setText(formatChannelUpdatedAt(updatedAt));
    if (!isSameDay(updatedAt, Date.now())) {
      return;
    }

    const id = setInterval(() => {
      if (isSameDay(updatedAt, Date.now())) {
        setText(formatChannelUpdatedAt(updatedAt));
        return;
      }
      clearInterval(id);
    }, 10000);

    return () => {
      clearInterval(id);
    };
  }, [updatedAt]);

  return (
    <Text
      style={[{ color: colors.tertiaryText }, styles.textXSmall, style]}
      {...props}
    >
      {text}
    </Text>
  );
}
