import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';
import Chance from 'chance';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import {
  PRIMARY_USER_NAME,
  SCENARIO_VERSION,
  USER_METADATA_KEYS,
} from 'inbox-constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppState,
  LogBox,
  Platform,
  StyleSheet,
  UIManager,
  View,
} from 'react-native';
import ReactNativeModal from 'react-native-modal';
import { NotifierWrapper } from 'react-native-notifier';
import { QueryClient, QueryClientProvider } from 'react-query';

import AppSkeleton from './components/AppSkeleton';
import CallScreen from './components/CallScreen';
import IconButton from './components/IconButton';
import Text from './components/Text';
import {
  asyncStorageKeys,
  colors,
  DEFAULT_FEMALE_PROFILE_URL,
  DEFAULT_MALE_PROFILE_URL,
} from './constants';
import themes from './constants/themes';
import {
  AuthContext,
  CallContext,
  ShareModalContext,
  ThemeContext,
  ThemeUpdaterContext,
} from './contexts';
import useCallScreen from './hooks/useCallScreen';
import ChatScreen from './screens/ChatScreen';
import HomeTabNavigator from './screens/HomeTabNavigator';
import LoginScreen from './screens/LoginScreen';
import NewConversationScreen from './screens/NewConversationScreen';
import SettingsScreen from './screens/SettingsScreen';
import styles from './styles';
import alert from './utils/alert';
import { initializeUser } from './utils/api';
import {
  registerPushToken,
  sendbird,
  unregisterPushToken,
} from './utils/sendbird';

LogBox.ignoreAllLogs();

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

const Stack =
  Platform.OS === 'web' ? createStackNavigator() : createNativeStackNavigator();
const SettingsStack = createNativeStackNavigator();

const chance = new Chance();

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0 } },
});

async function connectToSendBird({
  username,
  nickname,
  gender,
}: {
  username: string;
  nickname: string;
  gender?: 'male' | 'female';
}) {
  await sendbird.connect(username);

  const user = await sendbird.updateCurrentUserInfo(
    nickname,
    gender === 'female' ? DEFAULT_FEMALE_PROFILE_URL : DEFAULT_MALE_PROFILE_URL,
  );
  if (!__DEV__) {
    await user.updateMetaData({ [USER_METADATA_KEYS.os]: Platform.OS }, true);
  }
  await registerPushToken();

  console.log('connected to sendbird');
  return user;
}

async function getUserFromAsyncStorage() {
  try {
    const user = await AsyncStorage.getItem(asyncStorageKeys.savedUser);
    return user && JSON.parse(user);
  } catch {
    return null;
  }
}

async function saveUserToAsyncStorage(user) {
  try {
    if (user == null) {
      await AsyncStorage.removeItem(asyncStorageKeys.savedUser);
    } else {
      await AsyncStorage.setItem(
        asyncStorageKeys.savedUser,
        JSON.stringify(user),
      );
    }
  } catch {
    // ignore
  }
}

const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

const isThemeValid = (theme: string): theme is AppThemeKey =>
  Object.keys(themes).includes(theme);

export default function App() {
  const [currentUser, setCurrentUser] = useState<SendBird.User | null>(null);
  const [isInitialUserLoaded, setInitialUserLoaded] = useState(false);
  const [isInitializingUser, setIsInitializingUser] = useState(false);
  const { callScreenData, callContext, stopCall } = useCallScreen();
  const [theme, setTheme] = useState<AppThemeKey>('default');

  let [fontsLoaded] = useFonts({});

  const isAppReady = fontsLoaded && isInitialUserLoaded;
  const isSignedIn = !!currentUser;

  useEffect(() => {
    async function getThemeFromAsyncStorage() {
      try {
        const themeFromStorage = await AsyncStorage.getItem(
          asyncStorageKeys.theme,
        );
        if (themeFromStorage && isThemeValid(themeFromStorage)) {
          setTheme(themeFromStorage);
        }
      } catch (error) {
        // ignore
      }
    }
    getThemeFromAsyncStorage();
  }, []);

  useEffect(() => {
    async function saveThemeToAsyncStorage() {
      try {
        await AsyncStorage.setItem(asyncStorageKeys.theme, theme);
      } catch (error) {
        // ignore
      }
    }
    saveThemeToAsyncStorage();
  }, [theme]);

  const updateCurrentUserState = useCallback(async () => {
    const user = sendbird.currentUser;
    await saveUserToAsyncStorage(user);
    setCurrentUser(deepCopy(user));
  }, []);

  const initializeCurrentUser = useCallback(async () => {
    setIsInitializingUser(true);

    const userId = sendbird.currentUser.userId;
    const nickname = sendbird.currentUser.nickname;
    try {
      await sendbird.disconnect();
      queryClient.clear();
      await initializeUser(userId);
      await connectToSendBird({ username: userId, nickname });
      await updateCurrentUserState();

      setIsInitializingUser(false);
    } catch (error) {
      console.error(error);
      alert('Initialization failed.');
      await sendbird.disconnect();
      await connectToSendBird({ username: userId, nickname });
      await updateCurrentUserState();
    } finally {
      setIsInitializingUser(false);
    }
  }, [updateCurrentUserState]);

  const [isSigningOut, setIsSigningOut] = useState(false);

  const setUpNewUser = useCallback(async () => {
    setIsInitializingUser(true);
    try {
      // create a new user and sign in
      const userId = chance.guid();
      await connectToSendBird({
        username: userId,
        nickname: PRIMARY_USER_NAME,
        gender: 'male',
      });

      // initialize user
      await initializeUser(userId);
      await updateCurrentUserState();
    } catch (error) {
      console.error(error);
    } finally {
      setIsInitializingUser(false);
    }
  }, [updateCurrentUserState]);

  const authContext = useMemo(() => {
    return {
      currentUser,
      isInitializingUser,
      updateCurrentUserState,
      setIsInitializingUser,
      signIn: async ({ username, nickname }) => {
        await connectToSendBird({ username, nickname });
        if (
          (sendbird.currentUser.metaData as any).isInitialized ||
          (sendbird.currentUser.metaData as any).initData
        ) {
          await updateCurrentUserState();
        } else {
          await initializeCurrentUser();
        }
      },
      initializeCurrentUser,
      setUpNewUser,
      signOut: async () => {
        try {
          setIsSigningOut(true);
          await saveUserToAsyncStorage(null);
          await unregisterPushToken();
          await sendbird.disconnect();
          await queryClient.invalidateQueries();

          setCurrentUser(null);
        } catch (error) {
          console.error(error);
        } finally {
          setIsSigningOut(false);
        }
      },
      isSigningOut,
    };
  }, [
    currentUser,
    initializeCurrentUser,
    isInitializingUser,
    isSigningOut,
    setUpNewUser,
    updateCurrentUserState,
  ]);

  const [shareModalState, setShareModalState] = useState({
    title: '',
    isVisible: false,
    shareTargets: [],
    onSelect: () => {},
  });

  const shareModalContext = useMemo(() => {
    const modalProps = {
      title: shareModalState.title,
      isVisible: shareModalState.isVisible,
      setIsVisible: (isVisible) => {
        setShareModalState((state) => ({ ...state, isVisible }));
      },
      shareTargets: shareModalState.shareTargets,
      onSelect: shareModalState.onSelect,
    };
    return { modalProps, setShareModalState };
  }, [
    shareModalState.title,
    shareModalState.isVisible,
    shareModalState.shareTargets,
    shareModalState.onSelect,
  ]);

  useEffect(() => {
    async function prepare() {
      try {
        await SplashScreen.preventAutoHideAsync();
        const user = await getUserFromAsyncStorage();

        if (user) {
          setCurrentUser(user);
          if (!sendbird.currentUser) {
            const latestUser = await connectToSendBird({
              username: user.userId,
              nickname: user.nickname,
            });
            setCurrentUser(latestUser);

            if (
              latestUser.metaData[USER_METADATA_KEYS.initializedVersion] !==
              SCENARIO_VERSION
            ) {
              await initializeCurrentUser();
            }
          }
        } else {
          await setUpNewUser();
        }
      } catch (e) {
        console.warn(e);
      } finally {
        setInitialUserLoaded(true);
      }
    }
    prepare();
  }, [initializeCurrentUser, setUpNewUser, updateCurrentUserState]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      return;
    }

    const handleStateChange = (newState) => {
      if (newState === 'active') {
        sendbird.setForegroundState();
      } else {
        sendbird.setBackgroundState();
      }
    };

    const appStateListener = AppState.addEventListener(
      'change',
      handleStateChange,
    );

    return () => {
      appStateListener.remove();
    };
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (isAppReady) {
      await SplashScreen.hideAsync();
    }
  }, [isAppReady]);

  if (!isAppReady) {
    return Platform.OS === 'web' ? <AppSkeleton /> : null;
  }

  if (isInitializingUser) {
    return (
      <ThemeContext.Provider value={theme}>
        <AppSkeleton />
      </ThemeContext.Provider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContext.Provider value={theme}>
        <ThemeUpdaterContext.Provider value={setTheme}>
          <NotifierWrapper>
            <StatusBar style="dark" />
            <AuthContext.Provider value={authContext}>
              <CallContext.Provider value={callContext}>
                <ShareModalContext.Provider value={shareModalContext}>
                  <NavigationContainer>
                    <Stack.Navigator
                      screenOptions={{
                        headerTintColor: themes[theme].navigationTintColor,
                        headerTitleStyle: { color: colors.text },
                        headerShadowVisible: false,
                      }}
                    >
                      {isSignedIn ? (
                        <>
                          <Stack.Group
                            screenOptions={{
                              animationEnabled: true,
                              ...TransitionPresets.SlideFromRightIOS,
                            }}
                          >
                            <Stack.Screen
                              name="HomeTabNavigator"
                              component={HomeTabNavigator}
                              options={{ headerShown: false }}
                            />
                            <Stack.Screen
                              name="Chat"
                              component={ChatScreen}
                              options={{
                                headerBackTitleVisible: false,
                                headerShadowVisible: true,
                                headerTitle: () => <View />,
                              }}
                            />
                            <Stack.Screen
                              name="NewConversation"
                              component={NewConversationScreen}
                            />
                          </Stack.Group>
                          <Stack.Group
                            screenOptions={{
                              presentation: 'modal',
                              headerTitleAlign: 'center',
                              headerShown: false,
                              animationEnabled: true,
                              ...TransitionPresets.ModalSlideFromBottomIOS,
                            }}
                          >
                            <Stack.Screen
                              name="Settings"
                              children={() => (
                                <SettingsStack.Navigator>
                                  <SettingsStack.Screen
                                    name="SettingsHome"
                                    component={SettingsScreen}
                                    options={{
                                      headerTitle: 'Settings',
                                      header: ({ navigation }) => {
                                        return (
                                          <View style={_styles.settingsHeader}>
                                            <Text
                                              style={
                                                _styles.settingsHeaderTitle
                                              }
                                            >
                                              Settings
                                            </Text>
                                            <IconButton
                                              style={
                                                _styles.settingsHeaderCloseButton
                                              }
                                            >
                                              <MaterialIcons
                                                name="close"
                                                size={24}
                                                color={colors.text}
                                                onPress={() =>
                                                  navigation.goBack()
                                                }
                                              />
                                            </IconButton>
                                          </View>
                                        );
                                      },
                                    }}
                                  />
                                </SettingsStack.Navigator>
                              )}
                            />
                          </Stack.Group>
                        </>
                      ) : (
                        <Stack.Group
                          screenOptions={{
                            headerShown: false,
                            gestureEnabled: false,
                          }}
                        >
                          <Stack.Screen name="Login" component={LoginScreen} />
                        </Stack.Group>
                      )}
                    </Stack.Navigator>
                  </NavigationContainer>
                  <ReactNativeModal
                    isVisible={!!callScreenData}
                    animationIn="fadeIn"
                    style={{ justifyContent: 'flex-end', margin: 0 }}
                  >
                    {callScreenData ? (
                      <CallScreen data={callScreenData} onClose={stopCall} />
                    ) : (
                      <View />
                    )}
                  </ReactNativeModal>

                  <View onLayout={onLayoutRootView} />
                </ShareModalContext.Provider>
              </CallContext.Provider>
            </AuthContext.Provider>
          </NotifierWrapper>
        </ThemeUpdaterContext.Provider>
      </ThemeContext.Provider>
    </QueryClientProvider>
  );
}

const _styles = StyleSheet.create({
  settingsHeader: {
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
    backgroundColor: 'white',
  },
  settingsHeaderTitle: StyleSheet.flatten([
    styles.textMedium,
    styles.textSemibold,
  ]),
  settingsHeaderCloseButton: {
    position: 'absolute',
    right: 16,
  },
});
