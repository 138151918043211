import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { darken, rgba } from 'polished';
import { useState } from 'react';
import { FlatList, ImageSourcePropType, Pressable, View } from 'react-native';

import { core, localImageMap } from '../constants';
import useThemeValues from '../hooks/useThemeValues';
import CircleProgress from './CircleProgress';
import Image from './Image';
import Text from './Text';

type CarouselItem = {
  id: string;
  name: string;
  image?: ImageSourcePropType;
  localImageName?: string;
  price: string;
  url?: string;
  isSelected?: boolean;
};

const Carousel = ({
  onItemSelect,
  buttonLabel = 'Select',
  data = [],
}: {
  onItemSelect: (item: CarouselItem) => void;
  buttonLabel?: string;
  data?: CarouselItem[];
}) => {
  const theme = useThemeValues();
  const [loadingItemIndex, setLoadingItemIndex] = useState(-1);
  const hasSelectedItem = data.some((item) => item.isSelected);

  const handleItemSelect = async ({ item, index }) => {
    setLoadingItemIndex(index);
    try {
      await onItemSelect?.(item);
    } finally {
      setLoadingItemIndex(-1);
    }
  };

  return (
    <FlatList
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      data={data}
      pagingEnabled={true}
      contentContainerStyle={{
        paddingVertical: 4,
        paddingLeft: 50,
        paddingRight: 14,
      }}
      keyExtractor={(item) => item.name}
      renderItem={({ item, index }) => (
        <View
          style={[
            {
              display: 'flex',
              borderRadius: 6,
              borderWidth: 1,
              borderColor: '#D9D9D9',
              marginHorizontal: 2.5,
              backgroundColor: '#F2F2F2',
              width: 150,
              overflow: 'hidden',
            },
          ]}
        >
          <Image
            source={item.image || localImageMap[item.localImageName]}
            style={{
              width: 148,
              height: 126,
              backgroundColor: 'white',
            }}
          />
          <Text
            numberOfLines={2}
            style={[
              {
                fontSize: 13,
                marginVertical: 8,
                paddingHorizontal: 10,
              },
            ]}
          >
            {item.name}
          </Text>
          <Text
            style={{
              fontSize: 17,
              fontWeight: '600',
              paddingHorizontal: 10,
              marginTop: 4,
            }}
          >
            {item.price}
          </Text>
          <Pressable
            style={({ pressed }) => ({
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 12,
              paddingVertical: 12,
              borderTopWidth: 1,
              borderColor: rgba('black', 0.12),
              backgroundColor: pressed ? darken(0.02, '#F2F2F2') : '#F2F2F2',
            })}
            onPress={() => handleItemSelect({ item, index })}
            disabled={hasSelectedItem || loadingItemIndex > -1}
          >
            {index === loadingItemIndex ? (
              <View
                style={{
                  width: 24,
                  height: 24,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircleProgress size={20} />
              </View>
            ) : item.isSelected ? (
              <MaterialIcons name="done" size={24} color={theme.accent} />
            ) : (
              <Text
                style={{
                  fontSize: 16,
                  color: hasSelectedItem ? core.content.disabled : '@accent',
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                {buttonLabel}
              </Text>
            )}
          </Pressable>
        </View>
      )}
    />
  );
};

export default Carousel;
