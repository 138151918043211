import { BlurView } from 'expo-blur';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { colors, tabs } from '../constants';
import useThemeValues from '../hooks/useThemeValues';
import styles from '../styles';
import Image from './Image';

const tabBarImages = {
  [tabs.inbox]: require('../assets/ic-tab-inbox.png'),
  [tabs.people]: require('../assets/ic-tab-people.png'),
  [tabs.explore]: require('../assets/ic-tab-explore.png'),
};

const TabContainer = (props) => {
  if (Platform.OS === 'web') {
    return <View {...props} />;
  }
  return <SafeAreaView {...props} />;
};

export default function TabBar({ state, descriptors, navigation }) {
  const theme = useThemeValues();
  return (
    <BlurView
      intensity={100}
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderTopWidth: StyleSheet.hairlineWidth,
        borderColor: colors.border,
      }}
      tint="light"
    >
      <TabContainer
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
        }}
        edges={['bottom']}
      >
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              // The `merge: true` option makes sure that the params inside the tab screen are preserved
              navigation.navigate({ name: route.name, merge: true });
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });

            navigation.push('Settings');
          };

          return (
            <Pressable
              key={route.key}
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={[styles.centerChildren, { width: 80, height: 52 }]}
              disabled={route.name !== tabs.inbox}
            >
              <Image
                source={tabBarImages[route.name]}
                style={{
                  width: 40,
                  height: 40,
                  tintColor: isFocused ? theme.activeTab : theme.inactiveTab,
                }}
              />
            </Pressable>
          );
        })}
      </TabContainer>
    </BlurView>
  );
}
