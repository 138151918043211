import { formatDistanceToNowStrict, isSameDay } from 'date-fns';
import { channelCustomTypes, USER_METADATA_KEYS } from 'inbox-constants';
import LZString from 'lz-string';

import Image from '../components/Image';
import { isFAQBotAnswer } from './desk';
import { sendbird } from './sendbird';

if (typeof Intl === 'undefined') {
  require('intl');
  require('intl/locale-data/jsonp/en');
}

const channelNameMaxMembers = 3;

export function getChannelCounterpart(channel) {
  if (channel.members.length !== 2 || !sendbird.currentUser) {
    return null;
  }
  const otherUser = channel.members.find(
    (member) => member.userId !== sendbird.currentUser?.userId,
  );
  return otherUser;
}

const verifiedChannelCustomTypes = [
  channelCustomTypes.customerSupportBot,
  channelCustomTypes.marketing,
  channelCustomTypes.notifications,
  channelCustomTypes.notifications2,
  channelCustomTypes.promotions,
  channelCustomTypes.promotionCarousel,
  channelCustomTypes.support,
  channelCustomTypes.delivery,
];

export function isChannelVerified(channel) {
  const otherUser = getChannelCounterpart(channel);

  return (
    verifiedChannelCustomTypes.includes(channel.customType) ||
    otherUser?.metaData?.badge === 'verified'
  );
}

export function getChannelTitle({ channel, ticket, isChannelList }) {
  const currentUserId = sendbird.currentUser?.userId;

  if (ticket) {
    return ticket.agent?.name || ticket.title;
  }

  if (!channel) {
    return '';
  }

  if (isChannelVerified(channel)) {
    return channel.name;
  }

  if (channel.name && channel.name !== 'Group Channel') {
    return channel.members.length < 3
      ? channel.name
      : `${channel.name}${isChannelList ? ` (${channel.members.length})` : ''}`;
  }

  const nicknames = channel.members
    .filter((m) => m.userId !== currentUserId)
    .map((m) => m.nickname);

  if (nicknames.length > channelNameMaxMembers) {
    return `${nicknames.slice(0, channelNameMaxMembers + 1).join(', ')} and ${
      nicknames.length - channelNameMaxMembers
    } others`;
  }
  return `${nicknames.join(', ')}`;
}

export const intlTimeFormat = Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
});

export const intlDateFormat = Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
});

export const intlDateTimeFormat = Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

export const intlDateLineLastYearFormat = Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});

export const intlDateLineFormat = Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
  weekday: 'short',
});

export function getRandomAvatarUrl(userId) {
  return `https://avatars.dicebear.com/api/miniavs/${userId}.png`;
}

const formatDistanceLocale = {
  lessThanXSeconds: {
    one: 'just now',
    other: 'less than {{count}}s',
  },

  xSeconds: {
    one: '1s',
    other: '{{count}}s',
  },

  halfAMinute: 'half a minute',

  lessThanXMinutes: {
    one: 'less than a minute',
    other: 'less than {{count}} minutes',
  },

  xMinutes: {
    one: '1m',
    other: '{{count}}m',
  },

  aboutXHours: {
    one: 'about 1 hour',
    other: 'about {{count}} hours',
  },

  xHours: {
    one: '1h',
    other: '{{count}}h',
  },

  xDays: {
    one: '1 day',
    other: '{{count}} days',
  },

  aboutXWeeks: {
    one: 'about 1 week',
    other: 'about {{count}} weeks',
  },

  xWeeks: {
    one: '1 week',
    other: '{{count}} weeks',
  },

  aboutXMonths: {
    one: 'about 1 month',
    other: 'about {{count}} months',
  },

  xMonths: {
    one: '1 month',
    other: '{{count}} months',
  },

  aboutXYears: {
    one: 'about 1 year',
    other: 'about {{count}} years',
  },

  xYears: {
    one: '1 year',
    other: '{{count}} years',
  },

  overXYears: {
    one: 'over 1 year',
    other: 'over {{count}} years',
  },

  almostXYears: {
    one: 'almost 1 year',
    other: 'almost {{count}} years',
  },
};

const formatDistance = (token, count, options) => {
  let result;

  const tokenValue = formatDistanceLocale[token];
  if (typeof tokenValue === 'string') {
    result = tokenValue;
  } else if (count === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other.replace('{{count}}', count.toString());
  }

  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return `in ${result}`;
    } else {
      return `${result} ago`;
    }
  }

  return result;
};

export function formatDistanceToNow(timestamp) {
  return formatDistanceToNowStrict(timestamp, {
    addSuffix: true,
    locale: { formatDistance },
  });
}

export function getChannelUpdatedAt(channel) {
  return channel.lastMessage?.createdAt ?? channel.createdAt;
}

export function formatChannelUpdatedAt(updatedAt) {
  const formatDistanceToNowResult = formatDistanceToNow(updatedAt);
  const relativeTime =
    formatDistanceToNowResult === '0s ago' ? 'Now' : formatDistanceToNowResult;

  return isSameDay(updatedAt, new Date())
    ? relativeTime
    : intlDateFormat.format(updatedAt);
}

export function getChannelUpdatedAtLabel(channel) {
  const updatedAt = getChannelUpdatedAt(channel);
  return formatChannelUpdatedAt(updatedAt);
}

export function safeJSONParse(text) {
  try {
    return JSON.parse(text);
  } catch (e) {
    return null;
  }
}

const VerifiedBadge = ({ size = 16, style }) => (
  <Image
    source={require('../assets/ic-badge-verified.png')}
    style={[{ width: size, height: size }, style]}
  />
);

export function getUserBadge(user) {
  if (user?.metaData?.badge === 'verified') {
    return <VerifiedBadge />;
  }

  return null;
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function replaceNickname(text, nickname) {
  return text.replace(/\{nickname\}/g, nickname);
}

export function getLastMessage(channel) {
  if (channel.lastMessage) {
    if (channel.lastMessage.isFileMessage()) {
      if (channel.lastMessage.type.includes('image')) {
        return 'Sent an image';
      }
      if (channel.lastMessage.type.includes('audio')) {
        return 'Sent a voice memo';
      }
      return 'Sent a file';
    }
    return isFAQBotAnswer(channel.lastMessage)
      ? 'Here are some info you might find useful.'
      : channel.lastMessage.message.split('\n').join(' ');
  }
  return null;
}

export function getChannelBadge(channel) {
  if (isChannelVerified(channel)) {
    return <VerifiedBadge />;
  }

  const otherUser = getChannelCounterpart(channel);

  return otherUser ? getUserBadge(otherUser) : null;
}

export function isRealMessage(message) {
  return typeof message.messageId === 'number';
}

function getUserJourneyFlags() {
  try {
    return JSON.parse(
      LZString.decompressFromBase64(
        sendbird.currentUser.metaData[USER_METADATA_KEYS.userJourneyFlags],
      ),
    );
  } catch {
    return {};
  }
}

export function didPassUserJourneyStep(step) {
  return !!getUserJourneyFlags()[step];
}

export function setUserJourneyStep(step) {
  return new Promise((resolve, reject) => {
    sendbird.currentUser.updateMetaData(
      {
        [USER_METADATA_KEYS.userJourneyFlags]: LZString.compressToBase64(
          JSON.stringify({ ...getUserJourneyFlags(), [step]: true }),
        ),
      },
      true,
      (response, error) => {
        if (error) {
          return reject(error);
        }
        resolve();
      },
    );
  });
}

export async function getFirstMessageOfChannelWithCustomType(
  channelCustomType,
) {
  const channelListQuery =
    sendbird.GroupChannel.createMyGroupChannelListQuery();
  channelListQuery.customTypesFilter = [channelCustomType];
  channelListQuery.limit = 1;
  const [channel] = await channelListQuery.next();
  const messageListParams = new sendbird.MessageListParams();
  messageListParams.nextResultSize = 1;

  const [message] = await channel.getMessagesByTimestamp(0, messageListParams);
  return message;
}
