import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useContext } from 'react';
import { Platform, ScrollView, View } from 'react-native';

import Avatar from '../components/Avatar';
import Bubble from '../components/bubbles/Bubble';
import Button from '../components/Button';
import Image from '../components/Image';
import ScalePressable from '../components/ScalePressable';
import Spacer from '../components/Spacer';
import Text from '../components/Text';
import { BUBBLE_PADDING, colors } from '../constants';
import themes from '../constants/themes';
import { AuthContext, ThemeContext, ThemeUpdaterContext } from '../contexts';
import useThemeValues from '../hooks/useThemeValues';
import styles from '../styles';

const themeUniqueColors = Object.entries(themes).reduce(
  (acc, [key, themeValues]) => {
    const colorSet = new Set();
    Object.values(themeValues)
      .filter((value) => typeof value === 'string' && value.startsWith('#'))
      .forEach((value) => {
        colorSet.add(value);
      });
    return { ...acc, [key]: Array.from(colorSet) };
  },
  {},
);

function ThemesSection() {
  const currentTheme = useContext(ThemeContext);
  const updateTheme = useContext(ThemeUpdaterContext);
  const currentThemeValues = useThemeValues();

  return (
    <>
      <Text style={[styles.textBold, styles.textLarge]}>Themes</Text>
      <Spacer size={16} />

      <View style={[styles.rowStack, { flexWrap: 'wrap' }]}>
        {Object.keys(themes).map((theme) => {
          const themeColors = themeUniqueColors[theme];
          return (
            <ScalePressable
              key={theme}
              style={{ marginRight: 8, marginBottom: 9 }}
              onPress={() => updateTheme(theme)}
            >
              <Bubble
                style={{
                  width: 160,
                  borderWidth: 2,
                  borderColor:
                    currentTheme === theme
                      ? currentThemeValues.accent
                      : '#F2F2F2',
                }}
              >
                <View style={[styles.rowStack]}>
                  <View
                    style={{
                      width: 160,
                      height: 80,
                      flexWrap: 'wrap',
                      backgroundColor: 'white',
                    }}
                  >
                    {themeColors.map((color, index) =>
                      index === 0 ? (
                        <View
                          style={{
                            backgroundColor: color,
                            width: 80,
                            height: 80,
                          }}
                        >
                          <Image
                            source={themes[theme].logo}
                            style={{
                              width: 40,
                              height: 40,
                              margin: 20,
                              tintColor: 'white',
                            }}
                          />
                        </View>
                      ) : (
                        <View
                          style={{
                            backgroundColor: color,
                            width: 20,
                            height: 20,
                          }}
                        />
                      ),
                    )}
                  </View>
                </View>

                <View
                  style={{
                    padding: BUBBLE_PADDING,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text style={{ fontWeight: '600' }}>{theme}</Text>
                  {currentTheme === theme && (
                    <MaterialIcons name="done" size={16} color={theme.accent} />
                  )}
                </View>
              </Bubble>
            </ScalePressable>
          );
        })}
      </View>
      <Spacer size={32} />
    </>
  );
}

export default function SettingsScreen() {
  const { currentUser, initializeCurrentUser } = useContext(AuthContext);

  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <ScrollView
        contentInsetAdjustmentBehavior="automatic"
        contentContainerStyle={{ padding: 16 }}
      >
        <Text style={[styles.textBold, styles.textLarge]}>Profile</Text>
        <Spacer size={16} />

        <View style={[styles.rowStack, { justifyContent: 'center' }]}>
          <Avatar
            size={60}
            user={currentUser}
            fallbackAvatarKey={currentUser.userId}
            style={{ backgroundColor: '#E2F8EB' }}
          />
          <Spacer size={16} />
          <View
            style={[styles.colStack, { alignItems: 'flex-start', flex: 1 }]}
          >
            <Text style={[styles.textBold, styles.textLarge]}>
              {currentUser.nickname}
            </Text>
            <Text style={[styles.textSmall, { color: colors.secondaryText }]}>
              {currentUser.userId}
            </Text>
          </View>
        </View>
        <Spacer size={32} />

        {Platform.OS !== 'web' && <ThemesSection />}

        <Button
          title="Reset"
          variant="secondary"
          onPress={() => {
            initializeCurrentUser();
          }}
        />
      </ScrollView>
    </View>
  );
}
