import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useMemo } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Markdown from 'react-native-markdown-display';
import ReactNativeModal from 'react-native-modal';

import { colors } from '../constants';
import useThemeValues from '../hooks/useThemeValues';
import { textStyles } from '../styles';
import IconButton from './IconButton';

export type ArticleModalProps = {
  isVisible: boolean;
  content: string;
  closeModal: () => void;
};

export default function ArticleModal({
  isVisible,
  closeModal,
  content,
}: ArticleModalProps) {
  const theme = useThemeValues();
  const markdownStyles = useMemo(
    () =>
      StyleSheet.create({
        heading1: {
          fontSize: 18,
          lineHeight: 24,
          fontWeight: 'bold',
          marginBottom: 12,
        },
        body: {
          ...textStyles.textSmedium,
          color: 'text',
        },
        link: { color: theme.accent },
        paragraph: { marginTop: 0, marginBottom: 0 },
      }),
    [theme.accent],
  );

  return (
    <ReactNativeModal
      isVisible={isVisible}
      backdropOpacity={0.32}
      style={{ margin: 0, marginTop: 12 + (Platform.OS === 'web' ? 44 : 0) }}
    >
      <View
        style={{
          backgroundColor: 'white',
          flex: 1,
          padding: 10,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <IconButton onPress={closeModal} style={{ alignSelf: 'flex-end' }}>
          <MaterialIcons name="close" size={24} color={colors.text} />
        </IconButton>
        <View style={{ flex: 1, paddingHorizontal: 16 }}>
          <Markdown style={markdownStyles}>{content}</Markdown>
        </View>
      </View>
    </ReactNativeModal>
  );
}
