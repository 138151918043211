import { useMemo } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { colors, primitive } from '../../constants';
import CircleProgress from '../CircleProgress';
import Text from '../Text';

function Action({ title, onPress, style, isLoading }) {
  const backgroundColor = useSharedValue(primitive.neutral[3]);
  const scale = useSharedValue(1);
  const animatedStyle = useAnimatedStyle(() => ({
    backgroundColor: withTiming(backgroundColor.value, { duration: 200 }),
    transform: [{ scale: withTiming(scale.value, { duration: 200 }) }],
  }));

  return (
    <Pressable
      disabled={isLoading}
      style={style}
      onPress={onPress}
      onPressIn={() => {
        scale.value = 0.98;
      }}
      onPressOut={() => {
        scale.value = 1;
        backgroundColor.value = primitive.neutral[3];
      }}
      onHoverIn={() => {
        backgroundColor.value = primitive.neutral[4];
      }}
      onHoverOut={() => {
        backgroundColor.value = primitive.neutral[3];
      }}
    >
      <Animated.View style={[componentStyles.action, animatedStyle]}>
        {isLoading ? (
          <CircleProgress size={16} color={colors.text} />
        ) : (
          <Text style={componentStyles.actionText}>{title}</Text>
        )}
      </Animated.View>
    </Pressable>
  );
}

export default function MessageActions({
  actions = [],
  style,
  onActionPress,
  ongoingAction,
}) {
  const normalizedActions = useMemo(() => {
    return actions.map((action) => {
      const actionLabel = typeof action === 'object' ? action.label : action;
      return { label: actionLabel };
    });
  }, [actions]);

  return (
    <View style={style}>
      {normalizedActions.map((action, index) => {
        const { label } = action;
        return (
          <Action
            key={label}
            style={[index > 0 && { marginTop: 4 }]}
            onPress={() => onActionPress?.(action)}
            title={label}
            isLoading={action === ongoingAction}
          />
        );
      })}
    </View>
  );
}

const componentStyles = StyleSheet.create({
  action: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    height: 36,
  },
  actionText: {
    fontSize: 12,
    fontWeight: '600',
  },
});
