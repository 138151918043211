import { createContext } from 'react';

export const AuthContext = createContext<{
  currentUser: SendBird.User | null;
  updateCurrentUserState: () => void;
}>({} as any);
export const CallContext = createContext<any>(undefined);
export const ShareModalContext = createContext<any>(undefined);

export const ThemeContext = createContext<AppThemeKey>('default');
export const ThemeUpdaterContext = createContext<(value: AppThemeKey) => void>(
  () => {},
);
