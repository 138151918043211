import { useNavigation } from '@react-navigation/native';
import { openBrowserAsync } from 'expo-web-browser';
import { useContext, useMemo } from 'react';
import {
  FlatList,
  StyleProp,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native';

import { core, localImageMap, TABLET_LANDSCAPE_WIDTH } from '../constants';
import { ThemeContext } from '../contexts';
import styles from '../styles';
import alert from '../utils/alert';
import { safeJSONParse } from '../utils/common';
import { sendbird } from '../utils/sendbird';
import Image from './Image';
import ScalePressable from './ScalePressable';
import Spacer from './Spacer';

const ITEM_WIDTH = 316;
const ITEM_HEIGHT = 160;

const Placeholder = ({ style }: { style?: StyleProp<ViewStyle> }) => {
  return (
    <View style={[_styles.item, { backgroundColor: core.bg[3] }, style]} />
  );
};

const SPACING = 16;

type BannerItem = {
  messageId: number;
  url?: string;
  channelCustomType?: string;
  localImageName?: string;
};

export default function PromotionCarousel({
  data,
}: {
  data: {
    items: {
      recentMessages: (
        | SendBird.UserMessage
        | SendBird.AdminMessage
        | SendBird.FileMessage
      )[];
    }[];
  };
}) {
  const theme = useContext(ThemeContext);
  const navigation = useNavigation();
  const { items = [] } = data;

  const banners: BannerItem[] = useMemo(() => {
    const messages = items
      .flatMap((item) => item.recentMessages)
      .filter(Boolean);

    return messages
      .map(({ messageId, data }) => ({ messageId, data: safeJSONParse(data) }))
      .filter((item) => !!item.data)
      .map(({ messageId, data }) => ({ ...data, messageId } as BannerItem));
  }, [items]);

  const { width: screenWidth } = useWindowDimensions();

  const onItemPress = async (item: BannerItem) => {
    if (item.url) {
      openBrowserAsync(item.url);
    } else if (item.channelCustomType) {
      const listQuery = sendbird.GroupChannel.createMyGroupChannelListQuery();
      listQuery.includeEmpty = true;
      listQuery.memberStateFilter = 'joined_only';
      listQuery.customTypesFilter = [item.channelCustomType];
      listQuery.limit = 1;

      try {
        const [channel] = await listQuery.next();
        if (!channel) {
          alert(
            'Channel not found',
            `Meant to be navigate to a channel with this custom type: ${item.channelCustomType}`,
          );
          return;
        }
        navigation.push('Chat', { channelUrl: channel.url });
      } catch (error) {
        console.error(error);
      }
    }
  };

  if (theme === 'brex') {
    const imageWidth = screenWidth - SPACING * 2;
    return (
      <ScalePressable
        onPress={() => {
          openBrowserAsync(
            'https://www.brex.com/learn/cash-management/separate-personal-and-business-expenses',
          );
        }}
      >
        <Image
          source={require('../assets/brex-banner.png')}
          style={{
            width: imageWidth,
            height: (imageWidth * 104) / 343,
            margin: SPACING,
          }}
        />
      </ScalePressable>
    );
  }

  return (
    <FlatList
      keyExtractor={(item) => String(item.messageId)}
      data={banners}
      scrollEnabled={banners.length > 0}
      showsHorizontalScrollIndicator={false}
      horizontal={true}
      snapToInterval={ITEM_WIDTH * 1.5 + SPACING * 2 - screenWidth / 2}
      decelerationRate="fast"
      ItemSeparatorComponent={() => <Spacer size={SPACING} />}
      contentContainerStyle={[
        { padding: SPACING },
        screenWidth > TABLET_LANDSCAPE_WIDTH && {
          flex: 1,
          justifyContent: 'center',
        },
      ]}
      ListEmptyComponent={() => (
        <View style={[styles.rowStack]}>
          <Placeholder style={{ marginRight: SPACING }} />
          <Placeholder style={{ marginRight: SPACING }} />
          <Placeholder />
        </View>
      )}
      renderItem={({ item }) => {
        return (
          <ScalePressable onPress={() => onItemPress(item)}>
            <Image
              source={localImageMap[item.localImageName]}
              style={_styles.item}
              resizeMode="cover"
            />
          </ScalePressable>
        );
      }}
    />
  );
}

const _styles = StyleSheet.create({
  item: {
    width: ITEM_WIDTH,
    height: ITEM_HEIGHT,
    borderRadius: 10,
  },
});
