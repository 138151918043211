import { StyleProp, View, ViewStyle } from 'react-native';

import useThemeValues from '../hooks/useThemeValues';
import Image from './Image';

export default function BrandLogoAvatar({
  size,
  style,
}: {
  size: number;
  style?: StyleProp<ViewStyle>;
}) {
  const theme = useThemeValues();

  return (
    <View
      style={[
        {
          width: size,
          height: size,
          borderRadius: size / 2,
          backgroundColor: theme.brandAvatarBackground,
          alignItems: 'center',
          justifyContent: 'center',
        },
        style,
      ]}
    >
      <Image
        source={theme.logo}
        style={{ width: size / 2, height: size / 2, tintColor: 'white' }}
        resizeMode="contain"
      />
    </View>
  );
}
