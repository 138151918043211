import axios from 'axios';
import Constants from 'expo-constants';

const baseURL = Constants.manifest?.extra?.API_HOST;

const axiosInstance = axios.create({ baseURL });

export function initializeUser(userId: string, options?: unknown) {
  return axiosInstance.post('/api/v2/initialize-user', {
    userId,
    options,
    timezoneOffset: new Date().getTimezoneOffset(),
  });
}

export function sendAction({
  userId,
  channelUrl,
  messageTimestamp,
  action,
  payload,
}: {
  userId: string;
  channelUrl: string;
  messageTimestamp: number;
  action: string;
  payload: any;
}) {
  return axiosInstance.post('/api/v2/action', {
    userId,
    channelUrl,
    messageTimestamp,
    action,
    payload,
  });
}
