import { StyleSheet, View } from 'react-native';

import styles from '../styles';
import CircleProgress from './CircleProgress';
import TabBar from './TabBar';
import Text from './Text';

export default function AppSkeleton() {
  return (
    <View style={componentStyles.container}>
      <View style={componentStyles.header}>
        <Text style={[styles.textXLarge, styles.textBold]}>Inbox</Text>
      </View>
      <View style={componentStyles.loadingWrapper}>
        <CircleProgress size={32} />
      </View>
      <TabBar
        state={{
          index: 0,
          routes: [
            { key: 'inbox', name: 'Inbox' },
            { key: 'people', name: 'People' },
            { key: 'explore', name: 'Explore' },
          ],
        }}
        descriptors={{
          inbox: { options: {} },
          people: { options: {} },
          explore: { options: {} },
        }}
      />
    </View>
  );
}

const componentStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
  },
  header: {
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: '#CCCCCC',
    borderBottomWidth: 1,
  },
  loadingWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 52,
  },
});
