import { useNavigation, useRoute } from '@react-navigation/native';
import { isSameDay } from 'date-fns';
import {
  CHANNEL_METADATA_KEYS,
  channelCustomTypes,
  channelSuggestedRepliesMap,
  messageCustomTypes,
  MessageData,
  USER_METADATA_KEYS,
} from 'inbox-constants';
import { rgba } from 'polished';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {
  FlatList,
  LayoutAnimation,
  Platform,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import ImageView from 'react-native-image-viewing';
import { SafeAreaView } from 'react-native-safe-area-context';

import ArticleModal from '../../components/ArticleModal';
import BotResponseOptions from '../../components/BotResponseOptions';
import ChannelCover from '../../components/ChannelCover';
import ChatMessage from '../../components/ChatMessage';
import CircleProgress from '../../components/CircleProgress';
import IconButton from '../../components/IconButton';
import KeyboardAvoidingView from '../../components/KeyboardAvoidingView';
import LanguageButton from '../../components/LanguageButton';
import ListItemWrapper from '../../components/ListItemWrapper';
import SelectionModal from '../../components/SelectionModal';
import ShareModal from '../../components/ShareModal';
import Spacer from '../../components/Spacer';
import Text from '../../components/Text';
import { colors, languageNames } from '../../constants';
import { AuthContext, CallContext, ShareModalContext } from '../../contexts';
import useCurrentUserLanguage from '../../hooks/useCurrentUserLanguage';
import useTranslatedMessage from '../../hooks/useTranslatedMessage';
import styles from '../../styles';
import alert from '../../utils/alert';
import {
  getChannelBadge,
  getChannelCounterpart,
  getChannelTitle,
  intlDateTimeFormat,
  isChannelVerified,
  safeJSONParse,
} from '../../utils/common';
import { isFAQBotAnswer, isMessageVisible } from '../../utils/desk';
import formatDuration from '../../utils/formatDuration';
import { sendbird } from '../../utils/sendbird';
import CenteredAdminMessage from './CenteredAdminMessage';
import ChatInput from './ChatInput';
import CouldNotRecognizeMessage from './CouldNotRecognizeMessage';
import openImagePicker from './openImagePicker';
import SendButton from './SendButton';
import SendFileButton from './SendFileButton';

const QUERY_LIMIT = 100;

function createListQuery(channel: SendBird.GroupChannel) {
  const listQuery = channel.createPreviousMessageListQuery();
  listQuery.limit = QUERY_LIMIT;
  listQuery.includeReactions = true;
  listQuery.reverse = true;
  return listQuery;
}

function isSenderSame(message1, message2) {
  if (message1.isAdminMessage() !== message2.isAdminMessage()) {
    return false;
  }
  if (message1.isAdminMessage()) {
    return message1.customType === message2.customType;
  }
  return message1.sender.userId === message2.sender.userId;
}

function getMessageId(message) {
  return message.reqId || message.messageId;
}

function fetchNextMessages(listQuery: SendBird.PreviousMessageListQuery) {
  return new Promise<{
    messages: (
      | SendBird.UserMessage
      | SendBird.FileMessage
      | SendBird.AdminMessage
    )[];
    hasMore: boolean;
  }>((resolve, reject) => {
    listQuery.load((fetchedMessages, err) => {
      if (err) {
        reject(err);
        return;
      }

      const hasMore = fetchedMessages.length === QUERY_LIMIT;

      resolve({ messages: fetchedMessages, hasMore });
    });
  });
}

const nonCenteredAdminMessageCustomTypes = [
  messageCustomTypes.splitPayment,
  messageCustomTypes.splitCost,
  messageCustomTypes.csat,
  messageCustomTypes.csat5,
  messageCustomTypes.couldNotRecognize,
];

function isCenteredAdminMessage(
  message: SendBird.UserMessage | SendBird.AdminMessage | SendBird.FileMessage,
  channel: SendBird.GroupChannel,
) {
  if (!message.isAdminMessage()) {
    return false;
  }
  if (
    message.customType === messageCustomTypes.notification &&
    channel.customType === channelCustomTypes.notifications2
  ) {
    return false;
  }
  return !nonCenteredAdminMessageCustomTypes.includes(
    message.customType as any,
  );
}

function getChannelSubtitle(channel) {
  if (isChannelVerified(channel)) {
    return null;
  }

  if (channel.members.length > 2) {
    return (
      <Text
        style={[
          { marginTop: -4, color: colors.secondaryText },
          styles.textXSmall,
        ]}
      >
        {channel.members.length} people
      </Text>
    );
  }

  return (
    <View
      style={{
        alignItems: 'center',
        display: 'flex',
        marginTop: -4,
        flexDirection: 'row',
      }}
    >
      <View
        style={{
          marginRight: 4,
          width: 8,
          height: 8,
          borderRadius: 4,
          backgroundColor: colors.onlineColor,
        }}
      />
      <Text style={[{ color: colors.secondaryText }, styles.textXSmall]}>
        Online now
      </Text>
    </View>
  );
}

const articleModalReducer = (
  state: { isVisible: boolean; content: string },
  action: { type: 'OPEN'; payload: { content: string } } | { type: 'CLOSE' },
) => {
  if (action.type === 'OPEN') {
    return { isVisible: true, content: action.payload.content };
  }
  if (action.type === 'CLOSE') {
    return { isVisible: false, content: '' };
  }
  return state;
};

const useArticleModal = () =>
  useReducer(articleModalReducer, { isVisible: false, content: '' });

export default function ChatScreen() {
  const { currentUser, updateCurrentUserState } = useContext(AuthContext);
  const language = useCurrentUserLanguage();
  const shareModalContext = useContext(ShareModalContext);
  const [articleModalState, dispatchArticleModalAction] = useArticleModal();
  const [imageModalUri, setImageModalUri] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [channel, setChannel] = useState<SendBird.GroupChannel | null>(null);
  const route = useRoute();
  const navigation = useNavigation();
  const { channelUrl } = route.params as { channelUrl: string };
  const [input, setInput] = useState('');
  const { toggleCallScreen, addCallEventListener, removeCallEventListener } =
    useContext(CallContext);
  const [isSendingFileMessage, setIsSendingFileMessage] = useState(false);
  const [channelMetaData, setChannelMetaData] = useState({});
  const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(false);

  const [{ messages }, setFetchMessageState] = useState({
    messages: [] as (
      | SendBird.UserMessage
      | SendBird.AdminMessage
      | SendBird.FileMessage
    )[],
    hasMore: false,
    status: 'loading',
    error: null as unknown,
  });

  const deleteMessage = useCallback((id) => {
    setFetchMessageState((state) => {
      const newMessages = state.messages.filter(
        (message) =>
          message.messageId !== id &&
          (message as SendBird.UserMessage).reqId !== id,
      );
      return { ...state, messages: newMessages };
    });
  }, []);

  useEffect(() => {
    if (channel) {
      channel.getAllMetaData().then((metadata) => {
        setChannelMetaData(metadata);
      });
    }
  }, [channel]);

  const upsertMessage = useCallback((newMessage) => {
    setFetchMessageState(({ messages, ...state }) => {
      const newMessageId = getMessageId(newMessage);
      const existingMessageIndex = messages.findIndex(
        (m) => getMessageId(m) === newMessageId,
      );
      if (existingMessageIndex > -1) {
        const newMessages = [...messages];
        newMessages[existingMessageIndex] = newMessage;
        return {
          ...state,
          messages: newMessages,
        };
      } else {
        return {
          ...state,
          messages: [newMessage, ...messages].sort(
            (message1, message2) => message2.createdAt - message1.createdAt,
          ),
        };
      }
    });
  }, []);

  const [lastMessage] = messages;

  const sendUserMessage = useCallback(
    async (message: string, options?: { data?: any; customType?: string }) => {
      if (!channel || !message.trim()) {
        return;
      }

      const params = new sendbird.UserMessageParams();
      params.customType = options?.customType ?? '';
      params.data = JSON.stringify({
        version: '2.0',
        ...(options?.data ? options.data : {}),
      });
      params.translationTargetLanguages = ['es', 'ko', 'en'];
      params.message = message.trim();

      return new Promise<void>((resolve) => {
        const tempMessage = channel.sendUserMessage(
          params,
          (newMessage, error) => {
            if (error) {
              console.error(error);
              deleteMessage(tempMessage.reqId);
            } else {
              upsertMessage(newMessage);
            }
            resolve();
          },
        );
        upsertMessage(tempMessage);
      });
    },
    [channel, deleteMessage, upsertMessage],
  );

  useEffect(() => {
    addCallEventListener('chat', {
      onCallEnded: (duration) => {
        sendUserMessage(formatDuration(duration), {
          customType: messageCustomTypes.callEnded,
        });
      },
    });

    return () => {
      removeCallEventListener('chat');
    };
  }, [addCallEventListener, removeCallEventListener, sendUserMessage]);

  const [listQuery, setListQuery] =
    useState<SendBird.PreviousMessageListQuery | null>(null);

  useEffect(() => {
    if (!listQuery || !channel) {
      return;
    }

    async function refresh() {
      try {
        if (!listQuery || !channel) {
          return;
        }

        const { messages, hasMore } = await fetchNextMessages(listQuery);
        setFetchMessageState({
          messages,
          hasMore,
          status: 'success',
          error: null,
        });
        channel.markAsRead();
      } catch (error) {
        console.error(error);
        setFetchMessageState({
          messages: [],
          hasMore: false,
          status: 'error',
          error: error,
        });
      }
    }

    refresh();
  }, [listQuery, channel]);

  useEffect(() => {
    async function prepare() {
      if (!channelUrl) {
        return;
      }

      setIsInitialized(false);
      try {
        const channel = await sendbird.GroupChannel.getChannel(channelUrl);
        setChannel(channel);
        setListQuery(createListQuery(channel));
      } catch (error) {
        alert(String(error));
        navigation.navigate('Inbox' as any);
      } finally {
        setIsInitialized(true);
      }
    }

    prepare();
  }, [channelUrl, navigation]);

  const showCallScreen = useCallback(() => {
    const otherUser = getChannelCounterpart(channel);
    if (!otherUser) {
      return;
    }

    toggleCallScreen({ user: otherUser });
    sendUserMessage('Call started', {
      customType: messageCustomTypes.callStarted,
    });
  }, [channel, sendUserMessage, toggleCallScreen]);

  const typingUserId = channelMetaData[CHANNEL_METADATA_KEYS.typingUserId];

  useLayoutEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [typingUserId]);

  const { width: screenWidth } = useWindowDimensions();

  const updateTitle = useCallback(() => {
    const headerTitle = () =>
      channel && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <ChannelCover
            channel={channel}
            size={36}
            style={{ marginRight: 8 }}
          />
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text
                style={[
                  styles.textMedium,
                  { fontWeight: '600', marginRight: 2 },
                ]}
                numberOfLines={1}
              >
                {getChannelTitle({
                  channel,
                  ticket: undefined,
                  isChannelList: false,
                })}
              </Text>
              {getChannelBadge(channel)}
            </View>
            {getChannelSubtitle(channel)}
          </View>
        </View>
      );

    const headerRight = () => {
      if (!channel || !currentUser) {
        return null;
      }

      const otherUser = getChannelCounterpart(channel);
      const canCall = !!otherUser && !isChannelVerified(channel);

      return (
        <>
          {canCall && (
            <>
              <IconButton
                key="call"
                onPress={showCallScreen}
                tintColor="@navigationTintColor"
                source={require('../../assets/ic-call-outline.png')}
                size={21}
              />
              <Spacer size={4} />
            </>
          )}
          <LanguageButton
            key="language"
            onPress={() => setIsLanguageModalVisible(true)}
          />
        </>
      );
    };

    navigation.setOptions({
      header: () => {
        return (
          <View style={{ backgroundColor: 'white' }}>
            <SafeAreaView
              edges={['top']}
              style={[
                styles.rowStack,
                {
                  borderBottomWidth: StyleSheet.hairlineWidth,
                  borderColor: colors.border,
                },
              ]}
            >
              <View
                style={[
                  styles.rowStack,
                  {
                    width: screenWidth,
                    alignItems: 'center',
                    height: 56,
                    paddingHorizontal: 8,
                  },
                ]}
              >
                <IconButton
                  source={require('../../assets/ic-arrow-back.png')}
                  tintColor="@navigationTintColor"
                  onPress={() => navigation.goBack()}
                />
                <Spacer size={6} />
                {headerTitle()}
                <View
                  style={[
                    styles.rowStack,
                    { right: 8, position: 'absolute', alignItems: 'center' },
                  ]}
                >
                  {headerRight()}
                </View>
              </View>
            </SafeAreaView>
          </View>
        );
      },
      title: getChannelTitle({
        channel,
        ticket: undefined,
        isChannelList: false,
      }),
    });
  }, [channel, currentUser, navigation, screenWidth, showCallScreen]);

  useLayoutEffect(() => {
    updateTitle();
  }, [updateTitle]);

  const openImagePickerAsync = async () => {
    openImagePicker((params) => {
      setIsSendingFileMessage(true);
      channel?.sendFileMessage(params, (message, error) => {
        setIsSendingFileMessage(false);
        if (error) {
          console.error(error);
          return;
        }
        upsertMessage(message);
      });
    });
  };

  const [suggestedReplies, setSuggestedReplies] = useState<
    [{ en: string; ko: string; es: string }, number][]
  >([]);

  const lastMessageData: MessageData | undefined = useMemo(
    () => safeJSONParse(lastMessage?.data),
    [lastMessage?.data],
  );

  useLayoutEffect(() => {
    if (!channel) {
      return;
    }
    const updateSuggestedReplies = () => {
      const channelState = Number(channelMetaData[CHANNEL_METADATA_KEYS.state]);

      const suggestedRepliesMap =
        channelSuggestedRepliesMap[channel.customType || ''];
      if (suggestedRepliesMap) {
        setSuggestedReplies(suggestedRepliesMap[channelState] || []);
        return;
      }

      setSuggestedReplies([]);
    };

    updateSuggestedReplies();
  }, [channel, channelMetaData]);

  useEffect(() => {
    if (!channel) {
      return;
    }
    const channelUrl = channel.url;
    const channelHandler = new sendbird.ChannelHandler();
    const isChannelMatching = (
      targetChannel,
    ): targetChannel is SendBird.GroupChannel =>
      targetChannel.url === channel.url && channel.isGroupChannel();

    channelHandler.onMessageReceived = async (targetChannel, message) => {
      if (isChannelMatching(targetChannel)) {
        const updateChannel = () => {
          setChannel(targetChannel);
          channel.markAsRead();
          upsertMessage(message);
        };

        const senderId = message.isAdminMessage()
          ? undefined
          : message.sender?.userId;

        if (!senderId) {
          updateChannel();
          return;
        }

        try {
          const metadata = await targetChannel.getMetaData([
            CHANNEL_METADATA_KEYS.typingUserId,
          ]);
          if (metadata[CHANNEL_METADATA_KEYS.typingUserId] === senderId) {
            await targetChannel.updateMetaData(
              { [CHANNEL_METADATA_KEYS.typingUserId]: '' },
              true,
            );
            updateChannel();
          } else {
            updateChannel();
          }
        } catch {
          updateChannel();
        }
      }
    };

    channelHandler.onMessageUpdated = (targetChannel, message) => {
      if (isChannelMatching(targetChannel)) {
        setChannel(targetChannel);
        upsertMessage(message);
      }
    };

    channelHandler.onReactionUpdated = (targetChannel, event) => {
      if (isChannelMatching(targetChannel)) {
        setChannel(targetChannel);
        setFetchMessageState(({ messages, ...state }) => {
          return {
            ...state,
            messages: messages.map((message) => {
              if (message.messageId === event.messageId) {
                message.applyReactionEvent(event);
              }
              return message;
            }),
          };
        });
      }
    };

    channelHandler.onReadReceiptUpdated = (targetChannel) => {
      if (isChannelMatching(targetChannel)) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setChannel(targetChannel);
        setFetchMessageState(({ messages, ...state }) => ({
          ...state,
          messages: [...messages],
        }));
      }
    };

    channelHandler.onMessageDeleted = (targetChannel, messageId) => {
      if (isChannelMatching(targetChannel)) {
        setChannel(targetChannel);
        deleteMessage(messageId);
      }
    };

    channelHandler.onUserLeft = (targetChannel, user) => {
      if (
        isChannelMatching(targetChannel) &&
        user.userId === sendbird.currentUser?.userId
      ) {
        //@ts-ignore
        navigation.navigate('Inbox', { action: 'leave', data: { channelUrl } });
      }
    };

    channelHandler.onChannelDeleted = (deletedChannelUrl, channelType) => {
      if (deletedChannelUrl === channelUrl && channelType === 'group') {
        //@ts-ignore
        navigation.navigate('Inbox', {
          action: 'delete',
          data: { channelUrl },
        });
      }
    };

    const metaDataEventHandler = (targetChannel, metaData) => {
      if (isChannelMatching(targetChannel)) {
        setChannelMetaData((currentMetaData) => ({
          ...currentMetaData,
          ...metaData,
        }));
      }
    };

    channelHandler.onMetaDataDeleted = (targetChannel, keys) => {
      if (isChannelMatching(targetChannel)) {
        setChannelMetaData((metadata) => {
          const newMetadata = { ...metadata };
          keys.forEach((key) => {
            delete newMetadata[key];
          });
          return newMetadata;
        });
      }
    };
    channelHandler.onMetaDataUpdated = metaDataEventHandler;
    channelHandler.onMetaDataCreated = metaDataEventHandler;

    const channelHandlerId = `CHAT_${channelUrl}`;
    sendbird.addChannelHandler(channelHandlerId, channelHandler);

    return () => {
      sendbird.removeChannelHandler(channelHandlerId);
    };
  }, [channel, deleteMessage, navigation, upsertMessage]);

  const sendInteractiveChatResponse = async (answer: string) => {
    if (!lastMessage) {
      return;
    }

    const currentChannelState = Number(
      channelMetaData[CHANNEL_METADATA_KEYS.state],
    );

    sendUserMessage(answer, {
      customType: messageCustomTypes.interactiveResponse,
      data: { channelState: currentChannelState },
    });
  };

  const sendUserMessageFromTextInput = () => {
    const trimmedMessage = input.trim();
    if (trimmedMessage.length === 0) {
      return;
    }
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setInput('');

    if (lastMessageData?.freeTextResponseData) {
      sendUserMessage(trimmedMessage, {
        customType: messageCustomTypes.freeTextResponse,
        data: { freeTextResponseData: lastMessageData.freeTextResponseData },
      });
    } else {
      sendUserMessage(trimmedMessage);
    }
  };

  const isNicknameVisible = (item) => {
    return !item.isFollowedBySameSender;
  };

  const messageInputType = useMemo(() => {
    if (!channel || channel.customType === 'alerts') {
      return 'UNAVAILABLE';
    }

    return 'CHAT';
  }, [channel]);

  const inputPlaceholder = useMemo(() => {
    if (lastMessageData?.inputPlaceholder) {
      return lastMessageData?.inputPlaceholder;
    }

    return (
      {
        CHAT: 'Enter message',
      }[messageInputType] || 'Chat is unavailable with this channel.'
    );
  }, [lastMessageData?.inputPlaceholder, messageInputType]);

  const textInputProps = {
    value: input,
    multiline: true,
    blurOnSubmit: true,
    onSubmitEditing: sendUserMessageFromTextInput,
    returnKeyType: 'send',
    onChangeText: (text) => {
      if (text.length > 0) {
        channel?.startTyping();
      } else {
        channel?.endTyping();
      }
      if (!!text.trim() !== !!input.trim()) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);
      }
      setInput(text);
    },
    placeholder: inputPlaceholder,
    editable: isInitialized && messageInputType === 'CHAT',
  };

  const messageListItems = useMemo(() => {
    if (!channel) {
      return [];
    }
    const typingUser = channel.members.find(
      (user) => user.userId === typingUserId,
    );

    const messagesWithTypingIndicator = typingUser
      ? [
          {
            messageId: 'typing',
            sender: typingUser,
            message: 'Typing...',
            customType: 'typingIndicator',
            isAdminMessage: () => false,
            isUserMessage: () => true,
            isFileMessage: () => false,
            createdAt: lastMessage?.createdAt ?? Date.now(),
          } as unknown as SendBird.UserMessage,
          ...messages,
        ]
      : messages;

    const items = messagesWithTypingIndicator
      .filter(isMessageVisible)
      .map((message, index, visibleMessages) => {
        const isFirstMessageOfDay =
          index === visibleMessages.length - 1 ||
          !isSameDay(message.createdAt, visibleMessages[index + 1].createdAt);

        const isFollowingSameSender =
          index > 0 &&
          isSenderSame(visibleMessages[index - 1], message) &&
          isFAQBotAnswer(message) ===
            isFAQBotAnswer(visibleMessages[index - 1]);

        const isFollowedBySameSender =
          index < visibleMessages.length - 1 &&
          isSenderSame(message, visibleMessages[index + 1]) &&
          isFAQBotAnswer(message) ===
            isFAQBotAnswer(visibleMessages[index + 1]);

        const isFollowedBySameTimestamp =
          index < visibleMessages.length - 1 &&
          intlDateTimeFormat.format(message.createdAt) ===
            intlDateTimeFormat.format(visibleMessages[index + 1].createdAt);

        const isFollowingSameTimestamp =
          index > 0 &&
          intlDateTimeFormat.format(message.createdAt) ===
            intlDateTimeFormat.format(visibleMessages[index - 1].createdAt);

        const key = getMessageId(message);

        return {
          key,
          message,
          isFirstMessageOfDay,
          isFollowingSameSender,
          isFollowedBySameSender,
          isFollowedBySameTimestamp,
          isFollowingSameTimestamp,
          isTimestampVisible: false,
        };
      });

    [...items].reverse().forEach((item, index, reversedItems) => {
      const { message } = item;

      const isTimestampChanged =
        intlDateTimeFormat.format(message.createdAt) !==
        intlDateTimeFormat.format(reversedItems[index - 1]?.message.createdAt);

      const isSenderSame =
        index > 0 &&
        (message as SendBird.UserMessage).sender?.userId ===
          (reversedItems[index - 1].message as SendBird.UserMessage).sender
            ?.userId;

      if (isTimestampChanged || !isSenderSame) {
        item.isTimestampVisible = true;
      } else if (
        isSenderSame &&
        message.customType !== 'payment' &&
        reversedItems[index - 1].isTimestampVisible
      ) {
        item.isTimestampVisible = reversedItems[index - 1].isTimestampVisible;
        reversedItems[index - 1].isTimestampVisible = false;
      }
    });

    return items;
  }, [channel, lastMessage, messages, typingUserId]);

  const isSendingFileDisabled = messageInputType !== 'CHAT' || !channel;
  const getTranslatedMessage = useTranslatedMessage();

  return (
    <KeyboardAvoidingView
      style={[
        {
          flex: 1,
          backgroundColor: colors.background,
        },
        Platform.OS === 'web' && {
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        },
      ]}
      contentContainerStyle={{ flex: 1 }}
    >
      {isInitialized ? (
        <FlatList
          style={{ flex: 1, backgroundColor: 'white' }}
          data={messageListItems}
          inverted={true}
          ItemSeparatorComponent={({ leadingItem }) => {
            return <Spacer size={isNicknameVisible(leadingItem) ? 16 : 4} />;
          }}
          contentContainerStyle={{
            flexGrow: 1,
            paddingBottom: 16,
            paddingTop: suggestedReplies.length > 0 ? 0 : 16,
          }}
          renderItem={({ item }) => {
            if (!channel) {
              return null;
            }

            if (
              item.message.isAdminMessage() &&
              isCenteredAdminMessage(item.message, channel)
            ) {
              return (
                <ListItemWrapper>
                  <CenteredAdminMessage
                    message={getTranslatedMessage(item.message)}
                  />
                </ListItemWrapper>
              );
            }

            if (
              item.message.isAdminMessage() &&
              item.message.customType === messageCustomTypes.couldNotRecognize
            ) {
              return (
                <ListItemWrapper>
                  <CouldNotRecognizeMessage>
                    {item.message.message}
                  </CouldNotRecognizeMessage>
                </ListItemWrapper>
              );
            }

            return (
              <ListItemWrapper>
                <ChatMessage
                  channel={channel}
                  showImagePreview={setImageModalUri}
                  showFAQArticle={(content) =>
                    dispatchArticleModalAction({
                      type: 'OPEN',
                      payload: { content },
                    })
                  }
                  {...item}
                />
              </ListItemWrapper>
            );
          }}
          ListHeaderComponentStyle={{ flex: 1, justifyContent: 'flex-end' }}
          ListHeaderComponent={() => {
            if (suggestedReplies.length > 0) {
              const replyLabels = suggestedReplies.map(
                ([text]) => text[language] || text.en,
              );

              return (
                <BotResponseOptions
                  botResponseOptions={replyLabels}
                  sendResponseToBot={(option) => {
                    const index = replyLabels.indexOf(option);
                    const [selectedReplyTranslations, nextState] =
                      suggestedReplies[index];
                    sendInteractiveChatResponse(selectedReplyTranslations.en);

                    if (Array.isArray(suggestedReplies[index])) {
                      setChannelMetaData((metadata) => ({
                        ...metadata,
                        [CHANNEL_METADATA_KEYS.state]: nextState,
                      }));
                      channel?.updateMetaData(
                        { [CHANNEL_METADATA_KEYS.state]: nextState },
                        true,
                      );
                    }
                  }}
                />
              );
            }

            return null;
          }}
        />
      ) : (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircleProgress size={32} />
        </View>
      )}
      <SafeAreaView edges={['bottom']} style={{ backgroundColor: 'white' }}>
        <View
          style={[_styles.inputBar, { paddingRight: input.trim() ? 40 : 4 }]}
        >
          <SendFileButton
            disabled={isSendingFileDisabled}
            isLoading={isSendingFileMessage}
            onPress={openImagePickerAsync}
          />

          <ChatInput
            inputType={
              lastMessageData?.userInputType === 'number' ? 'money' : 'text'
            }
            textInputProps={textInputProps}
          />
          <SendButton
            isMessageEmpty={input.trim() === ''}
            onPress={sendUserMessageFromTextInput}
          />
        </View>
      </SafeAreaView>
      {imageModalUri && (
        <ImageView
          images={[{ uri: imageModalUri }]}
          imageIndex={0}
          visible
          onRequestClose={() => setImageModalUri(null)}
        />
      )}

      <ShareModal {...shareModalContext.modalProps} />
      <SelectionModal
        options={[languageNames.en, languageNames.es, languageNames.ko]}
        selectedOption={languageNames[language] || languageNames.en}
        onSelect={(option) => {
          setIsLanguageModalVisible(false);
          const match = Object.entries(languageNames).find(
            ([, value]) => value === option,
          );
          if (!match) {
            return;
          }
          sendbird.currentUser.updateMetaData(
            { [USER_METADATA_KEYS.language]: match[0] },
            true,
            (newMetaData, error) => {
              if (error) {
                console.error(error);
                return;
              }
              updateCurrentUserState();
            },
          );
        }}
        title="Translate to"
        layout={Platform.OS === 'android' ? 'dialog' : 'actionSheet'}
        isVisible={isLanguageModalVisible}
        onCancel={() => setIsLanguageModalVisible(false)}
      />
      <ArticleModal
        isVisible={articleModalState.isVisible}
        closeModal={() => dispatchArticleModalAction({ type: 'CLOSE' })}
        content={articleModalState.content}
      />
    </KeyboardAvoidingView>
  );
}

const _styles = StyleSheet.create({
  inputBar: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 56,
    paddingHorizontal: 4,
    borderTopWidth: 1,
    borderColor: rgba('black', 0.12),
    paddingVertical: 8,
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  avatar: {
    marginHorizontal: 8,
    marginBottom: 3,
  },
  messageRow: {
    paddingLeft: 8,
    paddingRight: 48,
    display: 'flex',
  },
  messageTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  bubble: {
    borderRadius: 18,
    paddingHorizontal: 12,
    paddingVertical: 7,
  },
  removeBubblePadding: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  question: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  link: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  timestamp: { color: colors.tertiaryText, marginHorizontal: 4 },
  dialog: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingVertical: 24,
    paddingHorizontal: 32,
    borderRadius: 16,
    marginTop: 16,
    borderWidth: 1,
    borderColor: '#0000001A',
  },
  dialogTitle: { fontSize: 16, lineHeight: 24, marginBottom: 16 },
  image: { borderRadius: 0 },
  divider: { width: '100%', height: 1, backgroundColor: '#DEDEDE' },
});
