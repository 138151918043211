import { View } from 'react-native';

import Text from '../components/Text';

export default function LoginScreen() {
  return (
    <View
      style={[
        {
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          flex: 1,
        },
      ]}
    >
      <Text>Something went wrong.</Text>
      <Text>We are working on getting this fixed.</Text>
    </View>
  );
}
