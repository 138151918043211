const themes: Record<AppThemeKey, AppTheme> = {
  default: {
    accent: '#6210CC',
    announcementBarBackgroundDefault: '#C5EAD2',
    announcementBarBackgroundHover: '#79D1A2',
    announcementBarIcon: '#0D0D0D',
    announcementBarContent: '#0D0D0D',
    brandAvatarBackground: '#6210CC',
    brandAvatarNotificationsWebBackground: '#6210CC',
    brandAvatarNotificationsMobileBackground: '#6A24C6',
    brandAvatarPromotionsWebBackground: '#6210CC',
    brandAvatarPromotionsMobileBackground: '#5959D3',
    brandAvatarSupportWebBackground: '#6210CC',
    brandAvatarSupportMobileBackground: '#A538D7',
    activeTab: '#6210CC',
    inactiveTab: '#CCCCCC',
    primaryButtonBackgroundDefault: '#6210CC',
    primaryButtonBackgroundHover: '#4B11A1',
    suggestedReplyBackgroundDefault: '#62EEAB',
    suggestedReplyBackgroundHover: '#39CF87',
    suggestedReplyText: '#0D0D0D',
    adminMessageBoxBackground: '#E1DBFF',
    csatSelectedItemBackground: '#E1DBFF',
    outgoingMessageBackground: '#6210CC',
    outgoingMessageText: '#FFFFFF',
    navigationTintColor: '#6210CC',
    logo: require('../assets/logo-sendbird.png'),
  },
  brex: {
    accent: '#E37344',
    announcementBarBackgroundDefault: '#212121',
    announcementBarBackgroundHover: '#3E3E3E',
    announcementBarIcon: '#E37344',
    announcementBarContent: '#FFFFFF',
    brandAvatarBackground: '#E37344',
    brandAvatarNotificationsWebBackground: '#E37344',
    brandAvatarNotificationsMobileBackground: '#E37344',
    brandAvatarPromotionsWebBackground: '#E37344',
    brandAvatarPromotionsMobileBackground: '#E37344',
    brandAvatarSupportWebBackground: '#E37344',
    brandAvatarSupportMobileBackground: '#E37344',
    activeTab: '#212121',
    inactiveTab: '#CCCCCC',
    primaryButtonBackgroundDefault: '#E37344',
    primaryButtonBackgroundHover: '#CE521E',
    suggestedReplyBackgroundDefault: '#4C0922',
    suggestedReplyBackgroundHover: '#684552',
    suggestedReplyText: '#FFFFFF',
    adminMessageBoxBackground: '#FFE2D6',
    csatSelectedItemBackground: '#FFE2D6',
    outgoingMessageBackground: '#E37344',
    outgoingMessageText: '#FFFFFF',
    navigationTintColor: '#0D0D0D',
    logo: require('../assets/logo-brex.png'),
  },
};

export default themes;
