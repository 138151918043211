import Image from '../../components/Image';
import OpacityPressable from '../../components/OpacityPressable';
import { colors } from '../../constants';

export default function SendButton({ isMessageEmpty, onPress }) {
  return (
    <OpacityPressable
      style={[
        {
          padding: 4,
          marginHorizontal: 4,
          position: 'absolute',
          right: isMessageEmpty ? -32 : 0,
          top: 12,
        },
      ]}
      onPress={onPress}
      disabled={isMessageEmpty}
    >
      <Image
        source={require('../../assets/ic-send.png')}
        style={{ width: 24, height: 24, tintColor: '@accent' }}
      />
    </OpacityPressable>
  );
}
