import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useNavigation } from '@react-navigation/native';
import Constants from 'expo-constants';
import { channelCustomTypes } from 'inbox-constants';
import { useInbox } from 'inbox-poc';
import { getInboxItemChannel } from 'inbox-poc/lib/utils';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { SectionList, StyleSheet, View } from 'react-native';

import CircleProgress from '../../components/CircleProgress';
import IconButton from '../../components/IconButton';
import InboxItem from '../../components/InboxItem';
import ListItemWrapper from '../../components/ListItemWrapper';
import NotificationBar from '../../components/NotificationBar';
import PromotionCarousel from '../../components/PromotionCarousel';
import TermsOfServiceModal from '../../components/TermsOfServiceModal';
import Text from '../../components/Text';
import { APP_ID, colors } from '../../constants';
import { AuthContext } from '../../contexts';
import useThemeValues from '../../hooks/useThemeValues';
import styles from '../../styles';
import { sendAction } from '../../utils/api';
import { sendbird } from '../../utils/sendbird';

const bannerChannelCustomTypes = [
  channelCustomTypes.promotionCarousel,
  channelCustomTypes.banner,
];

function isBannerChannel(channel) {
  return bannerChannelCustomTypes.includes(channel.customType);
}

export default function InboxScreen() {
  const theme = useThemeValues();
  const { currentUser, initializeCurrentUser, isInitializingUser } =
    useContext(AuthContext);
  const currentUserId = currentUser?.userId;
  const { data, isLoading, refetch, error } = useInbox({
    appId: APP_ID,
    userId: currentUserId,
    shouldIncludeRecentMessages: isBannerChannel,
    enabled: !isInitializingUser && !!currentUserId,
  });

  const [isPullToRefreshOngoing, setIsPullToRefreshOngoing] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerStyle: { height: Constants.statusBarHeight + 56 },
      headerTitleStyle: [styles.textXLarge, styles.textBold],
      headerTitleAlign: 'center',
      headerRight: () => (
        <View style={{ paddingEnd: 10 }}>
          <IconButton
            source={require('../../assets/ic-new-chat.png')}
            onPress={() => navigation.push('NewConversation')}
          />
        </View>
      ),
    });
  }, [data, navigation]);

  const ListEmptyComponent = isLoading && (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircleProgress size={32} />
    </View>
  );

  const onRefresh = useCallback(async () => {
    if (isLoading) {
      refetch();
      return;
    }
    setIsPullToRefreshOngoing(true);
    await refetch();
    setIsPullToRefreshOngoing(false);
  }, [isLoading, refetch]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const sections = useMemo(() => {
    if (isLoading && data.length === 0) {
      return [];
    }

    const { promotions, conversations, announcements } = data.reduce(
      (acc, cur) => {
        const channel = getInboxItemChannel(cur);
        if (
          [
            channelCustomTypes.notifications,
            channelCustomTypes.announcements,
          ].includes(channel.customType)
        ) {
          acc.announcements.push(cur);
        } else if (isBannerChannel(channel)) {
          acc.promotions.push(cur);
        } else {
          acc.conversations.push(cur);
        }
        return acc;
      },
      { promotions: [], conversations: [], announcements: [] },
    );

    const keyExtractor = (item) =>
      item.inboxItemType === 'channel'
        ? `channel_${item.channel.url}`
        : `ticket_${item.ticket.id}`;

    return [
      {
        key: 'announcement',
        keyExtractor,
        data: announcements,
        renderItem: ({ item }) => {
          const channel = getInboxItemChannel(item);
          if (channel.unreadMessageCount > 0) {
            return (
              <NotificationBar
                message={channel.lastMessage.message}
                onPress={() => setIsModalVisible(true)}
              />
            );
          } else {
            return null;
          }
        },
      },
      {
        key: 'promotions',
        keyExtractor: () => 'promotion',
        data: [{ items: promotions }],
        renderItem: ({ item }) => <PromotionCarousel data={item} />,
      },

      {
        key: 'conversations',
        keyExtractor,
        data: conversations,
        title: 'Conversations',
        renderItem: (props) => {
          const { item } = props;
          const channel = getInboxItemChannel(item);
          return (
            <ListItemWrapper>
              <InboxItem
                item={item}
                onPress={() => {
                  navigation.push('Chat', { channelUrl: channel.url });
                }}
              />
            </ListItemWrapper>
          );
        },
      },
    ];
  }, [data, navigation, isLoading]);

  const markNotificationsRead = async () => {
    const targetInboxItemIndex = data.findIndex(
      (item) =>
        getInboxItemChannel(item).customType ===
        channelCustomTypes.announcements,
    );

    if (targetInboxItemIndex >= 0) {
      const announcementsChannel = getInboxItemChannel(
        data[targetInboxItemIndex],
      );
      await sendAction({
        userId: sendbird.currentUser.userId,
        channelUrl: announcementsChannel.url,
        action: 'acceptTOS',
      });
      setIsModalVisible(false);
    }
  };

  return (
    <View
      style={{
        backgroundColor: colors.background,
        flex: 1,
      }}
    >
      <SectionList
        stickySectionHeadersEnabled={false}
        style={{ flex: 1 }}
        sections={sections}
        renderSectionHeader={({ section }) =>
          section.title ? (
            <ListItemWrapper>
              <Text
                style={[
                  styles.textLarge,
                  styles.textSemibold,
                  { paddingHorizontal: 16, paddingVertical: 8 },
                ]}
              >
                {section.title}
              </Text>
            </ListItemWrapper>
          ) : null
        }
        ListFooterComponent={
          sections.length > 0 && (
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 32,
              }}
            >
              <IconButton
                label="Reset demo"
                disabled={isInitializingUser}
                onPress={async () => {
                  await initializeCurrentUser();
                }}
              >
                <MaterialIcons name="refresh" size={20} color={theme.accent} />
              </IconButton>
            </View>
          )
        }
        ListEmptyComponent={ListEmptyComponent}
        contentContainerStyle={_styles.listContentContainer}
        onRefresh={onRefresh}
        refreshing={isPullToRefreshOngoing}
      />
      <TermsOfServiceModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        onAgree={markNotificationsRead}
      />
    </View>
  );
}

const _styles = StyleSheet.create({
  menu: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: 'white',
  },
  bold: { fontWeight: '700' },
  badge: {
    backgroundColor: colors.badge,
    height: 20,
    minWidth: 20,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 24,
  },
  listContentContainer: {
    flexGrow: 1,
    paddingBottom: 104,
  },
  inboxItem: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: 'white',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
