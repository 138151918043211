import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { isThisYear } from 'date-fns';
import * as WebBrowser from 'expo-web-browser';
import { messageCustomTypes, USER_METADATA_KEYS } from 'inbox-constants';
import emoji from 'node-emoji';
import { rgba } from 'polished';
import { Pressable, StyleSheet, View } from 'react-native';
import Autolink from 'react-native-autolink';

import { colors } from '../../constants';
import useThemeValues from '../../hooks/useThemeValues';
import useTranslatedMessage from '../../hooks/useTranslatedMessage';
import styles from '../../styles';
import { sendAction } from '../../utils/api';
import {
  intlDateLineFormat,
  intlDateLineLastYearFormat,
  isRealMessage,
  safeJSONParse,
} from '../../utils/common';
import { intlTimeFormat } from '../../utils/common';
import { sendbird } from '../../utils/sendbird';
import Avatar from '../Avatar';
import ActionsBubble from '../bubbles/ActionsBubble';
import AudioBubble from '../bubbles/AudioBubble';
import Bubble from '../bubbles/Bubble';
import MapBubble from '../bubbles/MapBubble';
import useBubbleMaxWidth from '../bubbles/useBubbleMaxWidth';
import WarningHeader from '../bubbles/WarningHeader';
import Carousel from '../Carousel';
import CircleProgress from '../CircleProgress';
import CSAT from '../CSAT';
import FAQArticles from '../FAQArticles';
import Image from '../Image';
import OrderConfirmation from '../OrderConfirmation';
import Spacer from '../Spacer';
import Text from '../Text';
import BillSplitting from './BillSplitting';
import faqContents from './faqContents';
import TypingIndicator from './TypingIndicator';

function isMyMessage(item) {
  return (
    item.sender?.userId === 'STORYBOOK_USER_ID' ||
    item.sender?.userId === sendbird.currentUser?.userId
  );
}

export default function ChatMessage({
  channel,
  message,
  showImagePreview,
  showFAQArticle,
  isFirstMessageOfDay,
  isFollowingSameSender,
  isFollowedBySameSender,
  isTimestampVisible,
}: {
  channel: SendBird.GroupChannel;
  message: SendBird.UserMessage | SendBird.AdminMessage | SendBird.FileMessage;
  showImagePreview: (url: string) => void;
  showFAQArticle: (content: string) => void;
  isFirstMessageOfDay: boolean;
  isFollowingSameSender: boolean;
  isFollowedBySameSender: boolean;
  isTimestampVisible: boolean;
}) {
  const { channelUrl } = message;

  const theme = useThemeValues();
  const bubbleMaxWidth = useBubbleMaxWidth();
  const _isMyMessage = isMyMessage(message);

  function renderFile() {
    const fileMessage = message as SendBird.FileMessage;
    if (fileMessage.type?.includes('image')) {
      const thumbnail =
        fileMessage.thumbnails[fileMessage.thumbnails.length - 1];
      const onPress = () => showImagePreview(fileMessage.url);

      if (thumbnail) {
        const { real_width, real_height, url } = thumbnail;

        return (
          <Pressable onPress={onPress}>
            <Bubble>
              <Image
                source={{ uri: url }}
                style={[
                  _styles.image,
                  real_width > real_height
                    ? { width: 160, height: (160 * real_height) / real_width }
                    : { height: 160, width: (160 * real_width) / real_height },
                ]}
              />
            </Bubble>
          </Pressable>
        );
      }

      return (
        <Pressable onPress={onPress}>
          <Bubble>
            <Image
              source={{ uri: fileMessage.url }}
              style={[_styles.image, { height: 160, aspectRatio: 1.5 }]}
            />
          </Bubble>
        </Pressable>
      );
    }

    if (fileMessage.type?.includes('audio')) {
      return <AudioBubble message={message} style={undefined} />;
    }
    return null;
  }

  const bubbleBackgroundColor = _isMyMessage
    ? theme.outgoingMessageBackground
    : colors.incomingMessageBackground;
  const messageTextColor = _isMyMessage
    ? theme.outgoingMessageText
    : colors.text;

  const getTranslatedMessage = useTranslatedMessage();

  const renderBubbles = (item) => {
    const parsedData = safeJSONParse(item.data);

    if (
      (
        [messageCustomTypes.csat, messageCustomTypes.csat5] as string[]
      ).includes(message.customType || '') &&
      !message.isFileMessage()
    ) {
      return (
        <CSAT
          type={
            message.customType === messageCustomTypes.csat5
              ? '5-scale'
              : 'binary'
          }
          score={parsedData.csat}
          question={getTranslatedMessage(message)}
          onSelect={async (score) => {
            await sendAction({
              userId: sendbird.currentUser.userId,
              channelUrl,
              messageTimestamp: message.createdAt,
              action: 'csat',
              payload: { score, messageId: message.messageId },
            });
          }}
        />
      );
    }

    if (parsedData?.actions) {
      return (
        <ActionsBubble
          cover={parsedData?.cover}
          header={
            parsedData?.warningHeader && (
              <WarningHeader
                title={parsedData.warningHeader.title}
                style={undefined}
              />
            )
          }
          width={252}
          message={item}
          actions={parsedData?.actions}
        />
      );
    }

    if (item.customType === 'typingIndicator') {
      return <TypingIndicator style={{ bubbleBackgroundColor }} />;
    }

    if (item.customType === messageCustomTypes.map) {
      return <MapBubble title={getTranslatedMessage(item)} />;
    }

    if (item.isFileMessage()) {
      return renderFile();
    }

    const isCallStatusMessage =
      item.customType === messageCustomTypes.callStarted ||
      item.customType === messageCustomTypes.callEnded;

    const textMessageBubble = (
      <View
        style={[
          _styles.bubble,
          { backgroundColor: bubbleBackgroundColor, maxWidth: bubbleMaxWidth },
        ]}
      >
        <View
          style={[
            styles.rowStack,
            { alignItems: 'center' },
            isCallStatusMessage && {
              width: 100,
              justifyContent: 'space-between',
            },
          ]}
        >
          {isCallStatusMessage && (
            <MaterialIcons name="call" size={20} color={messageTextColor} />
          )}
          <Autolink
            component={Text}
            text={getTranslatedMessage(item)}
            url
            email
            linkStyle={{ color: theme.accent }}
            onPress={(url) => WebBrowser.openBrowserAsync(url)}
            style={[
              { color: messageTextColor },
              parsedData?.type === 'responseToBot' &&
                parsedData?.inputMethod === 'select' && {
                  fontWeight: '600',
                },
            ]}
          />
          {item.customType === 'payment_request' &&
            parsedData?.status === 'processing' && (
              <CircleProgress size={16} style={{ marginLeft: 6 }} />
            )}
        </View>
      </View>
    );

    if (
      item.customType === messageCustomTypes.orderConfirmation &&
      typeof parsedData?.orderInfo === 'object'
    ) {
      return (
        <View style={{ width: 252 }}>
          {textMessageBubble}
          <OrderConfirmation
            {...parsedData.orderInfo}
            style={{ marginTop: 8 }}
          />
        </View>
      );
    }

    return textMessageBubble;
  };

  const isAvatarHidden = isFollowingSameSender;

  const isNicknameVisible = !isFollowedBySameSender;

  const parsedData = safeJSONParse(message.data);

  function renderFAQArticles() {
    function getFAQArticles() {
      try {
        if (Array.isArray(parsedData.faqArticles)) {
          return parsedData.faqArticles;
        }
        return [];
      } catch {
        return [];
      }
    }

    const faqArticles: string[] = getFAQArticles();
    if (faqArticles.length === 0) {
      return null;
    }

    return (
      <View style={[styles.rowStack, { marginTop: 8, marginBottom: 4 }]}>
        <Spacer size={52} />
        <FAQArticles
          items={faqArticles}
          onSelect={(text) => {
            const match = faqContents.find((item) => item.title === text);
            if (!match) {
              return;
            }
            showFAQArticle(`# ${match.title}\n\n${match.content}`);
          }}
        />
      </View>
    );
  }

  function renderCarousel() {
    if (
      !['wishlist', 'carousel', messageCustomTypes.vote].includes(
        message.customType as string,
      )
    ) {
      return null;
    }

    const buttonLabel = {
      wishlist: 'View Details',
      carousel: 'Buy Now',
      [messageCustomTypes.vote]: 'Select',
    }[message.customType || ''];

    return (
      <Carousel
        buttonLabel={buttonLabel}
        data={parsedData?.voteOptions}
        onItemSelect={async (item) => {
          switch (message.customType) {
            case 'wishlist':
              if (item.url) {
                await WebBrowser.openBrowserAsync(item.url).catch(
                  console.error,
                );
              }
              break;

            case messageCustomTypes.vote:
              await sendAction({
                channelUrl,
                userId: sendbird.currentUser.userId,
                messageTimestamp: message.createdAt,
                action: 'vote',
                payload: { item, messageId: message.messageId },
              });
              sendbird.currentUser.updateMetaData(
                {
                  [USER_METADATA_KEYS.selectedChocolateBoxKey]:
                    item.name.includes('Gold') ? 'gold' : 'silver',
                },
                true,
              );
              break;
            default:
          }
        }}
      />
    );
  }

  function renderMessage() {
    if (message.customType === messageCustomTypes.splitPayment) {
      const splitPaymentMessage = message as SendBird.AdminMessage;
      const messageData = safeJSONParse(splitPaymentMessage.data);
      return (
        <BillSplitting
          message={getTranslatedMessage(splitPaymentMessage)}
          sender={messageData.splitPayment.sender}
          currency="$"
          totalAmount={messageData.splitPayment.totalAmount}
          numberOfPeople={messageData.splitPayment.pendingPaymentCount}
        />
      );
    }

    return (
      <View
        style={[
          _styles.messageRow,
          {
            flexDirection: _isMyMessage ? 'row-reverse' : 'row',
            paddingHorizontal: 8,
            alignItems: 'flex-end',
          },
          _isMyMessage && { marginLeft: 8 },
          (message.reactions?.length ?? 0) > 0 && { paddingBottom: 22 },
        ]}
      >
        {!_isMyMessage && (
          <Avatar
            user={(message as SendBird.UserMessage).sender || undefined}
            size={28}
            channelCustomType={channel.customType || undefined}
            style={[_styles.avatar, { opacity: isAvatarHidden ? 0 : 1 }]}
          />
        )}
        <View
          style={[
            _styles.messageTextWrapper,
            {
              alignItems: _isMyMessage ? 'flex-end' : 'flex-start',
            },
          ]}
        >
          {!_isMyMessage &&
            (message as SendBird.UserMessage).sender &&
            isNicknameVisible && (
              <Text
                style={[
                  {
                    marginBottom: 2,
                    fontWeight: '700',
                    color: rgba('black', 0.5),
                    paddingLeft: 12,
                  },
                  styles.textXSmall,
                ]}
              >
                {(message as SendBird.UserMessage).sender?.nickname}
              </Text>
            )}
          {renderBubbles(message)}

          {(message.reactions?.length ?? 0) > 0 && (
            <View
              style={[
                _styles.reaction,
                {
                  right: _isMyMessage ? undefined : 4,
                  left: _isMyMessage ? 4 : undefined,
                  backgroundColor: bubbleBackgroundColor,
                },
              ]}
            >
              <Text style={[_styles.reactionText, { color: messageTextColor }]}>
                {emoji.get(message.reactions[0].key)}
                {message.reactions[0].userIds.length > 1
                  ? ` ${message.reactions[0].userIds.length}`
                  : ''}
              </Text>
            </View>
          )}
        </View>
        {isTimestampVisible && (
          <Text style={[_styles.timestamp, styles.textXSmall]}>
            {intlTimeFormat.format(message.createdAt)}
          </Text>
        )}
        {_isMyMessage &&
          isRealMessage(message) &&
          channel.getUnreadMemberCount(message) === 0 && (
            <Image
              source={require('../../assets/ic-done-all.png')}
              style={{
                width: 16,
                height: 16,
                position: 'relative',
                top: -2,
              }}
            />
          )}
      </View>
    );
  }
  return (
    <View>
      {isFirstMessageOfDay && (
        <Text
          style={{
            textAlign: 'center',
            color: rgba('black', 0.7),
            paddingVertical: 18,
          }}
        >
          {(isThisYear(message.createdAt)
            ? intlDateLineFormat
            : intlDateLineLastYearFormat
          ).format(message.createdAt)}
        </Text>
      )}
      {renderMessage()}
      {renderCarousel()}
      {renderFAQArticles()}
    </View>
  );
}

const _styles = StyleSheet.create({
  avatar: {
    marginHorizontal: 8,
    marginBottom: 3,
  },
  messageRow: {
    display: 'flex',
  },
  messageTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  bubble: {
    borderRadius: 18,
    paddingHorizontal: 12,
    paddingVertical: 7,
  },
  removeBubblePadding: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  question: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  link: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  timestamp: {
    color: colors.tertiaryText,
    marginHorizontal: 4,
    whiteSpace: 'nowrap',
  },
  dialog: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingVertical: 24,
    paddingHorizontal: 32,
    borderRadius: 16,
    marginTop: 16,
    borderWidth: 1,
    borderColor: '#0000001A',
  },
  dialogTitle: { fontSize: 16, lineHeight: 24, marginBottom: 16 },
  image: { borderRadius: 0 },
  reaction: {
    position: 'absolute',
    bottom: -24,
    minWidth: 30,
    height: 30,
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  reactionText: {
    fontSize: 16,
    lineHeight: 18,
    fontWeight: '700',
  },
});
