import { useMemo } from 'react';
import {
  ImageSourcePropType,
  Platform,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import useThemeValues from '../hooks/useThemeValues';
import Image from './Image';

type BrandAvatarType = 'notifications' | 'promotions' | 'support' | 'delivery';

const imageMap: Record<BrandAvatarType, ImageSourcePropType> = {
  notifications: require('../assets/avatar-notifications.png'),
  delivery: require('../assets/avatar-delivery.png'),
  support: require('../assets/avatar-support.png'),
  promotions: require('../assets/avatar-promotions.png'),
};

export default function BrandAvatar({
  size,
  style,
  type,
}: {
  size: number;
  style?: StyleProp<ViewStyle>;
  type: BrandAvatarType;
}) {
  const theme = useThemeValues();

  const backgroundColor = useMemo(() => {
    switch (type) {
      case 'notifications':
        return Platform.OS === 'web'
          ? theme.brandAvatarNotificationsWebBackground
          : theme.brandAvatarNotificationsMobileBackground;
      case 'support':
        return Platform.OS === 'web'
          ? theme.brandAvatarSupportWebBackground
          : theme.brandAvatarSupportMobileBackground;
      case 'promotions':
        return Platform.OS === 'web'
          ? theme.brandAvatarPromotionsWebBackground
          : theme.brandAvatarPromotionsMobileBackground;
      default:
        return theme.brandAvatarBackground;
    }
  }, [theme, type]);

  return (
    <View
      style={[
        { width: size, height: size, borderRadius: size / 2, backgroundColor },
        style,
      ]}
    >
      <Image source={imageMap[type]} style={{ width: size, height: size }} />
    </View>
  );
}
