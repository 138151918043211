import { StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { colors } from '../../constants';
import Spacer from '../Spacer';
import Text from '../Text';

const WarningIcon = (props) => (
  <Svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.583 12.28 7.03 1.166l6.446 11.112H.583Zm5.834-3.442v-3.5h1.166v3.5H6.417Zm0 1.167c0-.323.26-.584.583-.584h.006a.583.583 0 1 1 0 1.167H7a.583.583 0 0 1-.583-.583Z"
      fill="#D9352C"
    />
  </Svg>
);

export default function WarningHeader({ title, style }) {
  return (
    <View style={[componentStyles.title, style]}>
      <WarningIcon />
      <Spacer size={6} />
      <Text style={componentStyles.titleText}>{title}</Text>
    </View>
  );
}

const componentStyles = StyleSheet.create({
  title: { flexDirection: 'row', alignItems: 'center' },
  titleText: {
    fontSize: 12,
    fontWeight: '600',
    color: colors.text,
  },
});
