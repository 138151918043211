import {
  INTERACTIVE_CHAT_RESPONSES,
  messageCustomTypes,
} from 'inbox-constants';
import { useCallback } from 'react';

import useCurrentUserLanguage from './useCurrentUserLanguage';

export default function useTranslatedMessage() {
  const language = useCurrentUserLanguage();
  return useCallback(
    (
      message:
        | SendBird.UserMessage
        | SendBird.FileMessage
        | SendBird.AdminMessage,
    ) => {
      if (message.isFileMessage()) {
        return '';
      }
      if (message.customType === messageCustomTypes.interactiveResponse) {
        const matchingResponseKey = Object.keys(
          INTERACTIVE_CHAT_RESPONSES.new,
        ).find(
          (key) => INTERACTIVE_CHAT_RESPONSES.new[key].en === message.message,
        );
        if (matchingResponseKey) {
          return INTERACTIVE_CHAT_RESPONSES.new[matchingResponseKey][language];
        }
      }

      const translatedMessage = message.translations?.[language];
      return (translatedMessage || message.message || '').trim();
    },
    [language],
  );
}
