import { TouchableOpacity } from 'react-native';

import CircleProgress from '../../components/CircleProgress';
import Image from '../../components/Image';

export default function SendFileButton({ disabled, onPress, isLoading }) {
  return (
    <TouchableOpacity
      style={[
        {
          width: 40,
          height: 40,
          alignItems: 'center',
          justifyContent: 'center',
        },
        disabled && { opacity: 0.25 },
      ]}
      onPress={onPress}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <CircleProgress size={20} color="black" />
      ) : (
        <Image
          source={require('../../assets/ic-add.png')}
          style={{ width: 24, height: 24, tintColor: '@accent' }}
        />
      )}
    </TouchableOpacity>
  );
}
