import { useNavigation } from '@react-navigation/native';
import {
  botUserIds,
  CHANNEL_METADATA_KEYS,
  channelCustomTypes,
  SalesConciergeChannelState,
  USER_JOURNEY_FLAGS,
} from 'inbox-constants';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { UserMessage } from 'sendbird';

import { BUBBLE_PADDING, localImageMap } from '../../constants';
import { ShareModalContext } from '../../contexts';
import useFakeNewMessageAlert from '../../hooks/useFakeNewMessageAlert';
import useTranslatedMessage from '../../hooks/useTranslatedMessage';
import { sendAction } from '../../utils/api';
import {
  delay,
  didPassUserJourneyStep as isUserJourneyFlagSet,
  getFirstMessageOfChannelWithCustomType,
  setUserJourneyStep as setUserJourneyFlag,
} from '../../utils/common';
import { sendbird } from '../../utils/sendbird';
import Image from '../Image';
import Text from '../Text';
import Bubble from './Bubble';
import MessageActions from './MessageActions';
import useBubbleMaxWidth from './useBubbleMaxWidth';

export default function ActionsBubble({
  cover,
  width: widthProp,
  header,
  message: messageObject,
  actions = [],
}: {
  cover: string;
  width: number;
  header: ReactNode;
  message: UserMessage;
  actions?: string[] | { label: string }[];
}) {
  const { channelUrl, message, createdAt } = messageObject;
  const navigation = useNavigation<any>();
  const { setShareModalState } = useContext(ShareModalContext);
  const [currentHandlingAction, setCurrentHandlingAction] = useState(null);
  const currentHandlingActionRef = useRef(currentHandlingAction);
  const getTranslatedMessage = useTranslatedMessage();
  const sendFakeNewMessageAlert = useFakeNewMessageAlert();
  useEffect(() => {
    currentHandlingActionRef.current = currentHandlingAction;
  });

  const handleActionPress = async (action) => {
    if (currentHandlingActionRef.current) {
      return;
    }
    setCurrentHandlingAction(action);
    try {
      const sendActionParams = {
        userId: sendbird.currentUser.userId,
        channelUrl,
        messageTimestamp: createdAt,
        payload: undefined,
      };
      switch (action.label) {
        case 'Help': {
          const channelListQuery =
            sendbird.GroupChannel.createMyGroupChannelListQuery();
          channelListQuery.limit = 1;
          channelListQuery.customTypesFilter = [channelCustomTypes.support];
          const [channel] = await channelListQuery.next();
          navigation.goBack();
          navigation.navigate('Chat', { channelUrl: channel.url });
          break;
        }
        case 'Split the cost': {
          const listQuery = sendbird.createApplicationUserListQuery();
          listQuery.userIdsFilter = [
            botUserIds.daniel,
            botUserIds.grace,
            botUserIds.robin,
            botUserIds.angela,
          ];
          const channelListQuery =
            sendbird.GroupChannel.createMyGroupChannelListQuery();
          channelListQuery.limit = 1;
          channelListQuery.customTypesFilter = [channelCustomTypes.friends];
          const [channels, users] = await Promise.all([
            channelListQuery.next(),
            listQuery.next(),
          ]);

          setShareModalState({
            title: 'Split the cost with',
            isVisible: true,
            shareTargets: [...channels, ...users],
            onSelect: async () => {
              setShareModalState((state) => ({ ...state, isVisible: false }));
              setCurrentHandlingAction(action);
              await delay(1000);
              await setUserJourneyFlag(USER_JOURNEY_FLAGS.splitCost);
              const channel = await sendbird.GroupChannel.getChannel(
                channelUrl,
              );
              await channel.updateMetaData(
                {
                  [CHANNEL_METADATA_KEYS.state]:
                    SalesConciergeChannelState.AlexSplitsCost,
                },
                true,
              );

              const splitPaymentMessage =
                await getFirstMessageOfChannelWithCustomType(
                  channelCustomTypes.friends,
                );

              sendFakeNewMessageAlert({
                title: 'Pine Street Friends',
                description: getTranslatedMessage(splitPaymentMessage),
                targetChannelCustomType: channelCustomTypes.friends,
              });

              await channel.updateMetaData(
                {
                  [CHANNEL_METADATA_KEYS.state]: SalesConciergeChannelState.End,
                },
                true,
              );

              setCurrentHandlingAction(null);
            },
          });
          break;
        }
        case 'Pre-order':
          if (isUserJourneyFlagSet(USER_JOURNEY_FLAGS.preordered)) {
            return;
          }
          await sendAction({ ...sendActionParams, action: 'preorder' });
          await setUserJourneyFlag(USER_JOURNEY_FLAGS.preordered);
          break;
        case 'Share': {
          if (isUserJourneyFlagSet(USER_JOURNEY_FLAGS.shared)) {
            return;
          }
          const listQuery = sendbird.createApplicationUserListQuery();
          listQuery.userIdsFilter = [
            botUserIds.daniel,
            botUserIds.grace,
            botUserIds.robin,
            botUserIds.angela,
          ];
          const users = await listQuery.next();
          setShareModalState({
            title: 'Share with',
            isVisible: true,
            shareTargets: users,
            onSelect: async () => {
              setShareModalState((state) => ({ ...state, isVisible: false }));
              setCurrentHandlingAction(action);
              await sendAction({ ...sendActionParams, action: 'share' });
              await setUserJourneyFlag(USER_JOURNEY_FLAGS.shared);
              setCurrentHandlingAction(null);
            },
          });
          break;
        }
        case 'Renew':
          if (isUserJourneyFlagSet(USER_JOURNEY_FLAGS.renewed)) {
            return;
          }

          await sendAction({ ...sendActionParams, action: 'renew' });
          await setUserJourneyFlag(USER_JOURNEY_FLAGS.renewed);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCurrentHandlingAction(null);
    }
  };

  const width = Math.min(useBubbleMaxWidth(), widthProp);

  return (
    <Bubble
      style={[componentStyles.container, { width }, !!cover && { padding: 0 }]}
    >
      {cover && (
        <Image
          source={localImageMap[cover]}
          resizeMode="cover"
          style={{
            height: (width * 160) / 316,
            aspectRatio: 316 / 160,
          }}
        />
      )}
      <View style={{ padding: cover ? BUBBLE_PADDING : 0 }}>
        {header}
        <Text style={[componentStyles.message, !header && { paddingTop: 0 }]}>
          {getTranslatedMessage(messageObject)}
        </Text>
        <MessageActions
          actions={actions}
          onActionPress={handleActionPress}
          ongoingAction={currentHandlingAction}
          style={undefined}
        />
      </View>
    </Bubble>
  );
}

const componentStyles = StyleSheet.create({
  container: { padding: BUBBLE_PADDING },
  message: { paddingTop: 10, paddingBottom: BUBBLE_PADDING },
});
