import { channelCustomTypes } from 'inbox-constants';
import { getInboxItemChannel } from 'inbox-poc/lib/utils';
import { useContext } from 'react';
import { StyleSheet, View } from 'react-native';

import { colors, core } from '../constants';
import { AuthContext } from '../contexts';
import styles from '../styles';
import {
  getChannelBadge,
  getChannelTitle,
  getLastMessage,
} from '../utils/common';
import ChannelCover from './ChannelCover';
import ChannelUpdatedAt from './ChannelUpdatedAt';
import ListItem from './ListItem';
import Text from './Text';

export default function InboxItem({ onPress, item }) {
  const channel = getInboxItemChannel(item);
  const { currentUser } = useContext(AuthContext);
  const badge = getChannelBadge(channel);

  return (
    <ListItem viewStyle={_styles.inboxItem} onPress={onPress}>
      <ChannelCover
        channel={channel}
        size={60}
        style={{ position: 'relative', marginRight: 16 }}
      />
      <View style={{ flex: 1 }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              ellipsizeMode="tail"
              numberOfLines={1}
              style={[
                styles.textMedium,
                {
                  color: colors.text,
                  fontWeight: '600',
                  lineHeight: 25,
                },
              ]}
            >
              {getChannelTitle({
                channel: item.channel,
                ticket: item.ticket,
                currentUser,
                isChannelList: true,
              })}
            </Text>
            {!!badge && <View style={{ marginLeft: 4 }}>{badge}</View>}
          </View>
          <ChannelUpdatedAt
            channel={channel}
            style={[{ color: colors.tertiaryText, fontWeight: '500' }]}
          />
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <View style={[styles.colStack, { flex: 1 }]}>
            {channel.customType === channelCustomTypes.fakeClosedTicket && (
              <View
                style={[
                  styles.centerChildren,
                  {
                    paddingHorizontal: 6,
                    paddingVertical: 1,
                    backgroundColor: core.bg[3],
                    borderRadius: 2,
                    alignSelf: 'flex-start',
                  },
                ]}
              >
                <Text
                  style={[
                    styles.textXSmall,
                    { color: colors.tertiaryText, fontWeight: '500' },
                  ]}
                >
                  Closed Ticket
                </Text>
              </View>
            )}
            <Text
              style={[styles.textSmall, { color: colors.tertiaryText }]}
              ellipsizeMode="tail"
              numberOfLines={2}
            >
              {getLastMessage(channel)}
            </Text>
          </View>
          {channel.unreadMessageCount > 0 && (
            <View style={[_styles.badge]}>
              <Text
                style={[
                  styles.text,
                  styles.textBold,
                  styles.textXSmall,
                  { color: 'white', textAlign: 'center' },
                ]}
              >
                {channel.unreadMessageCount}
              </Text>
            </View>
          )}
        </View>
      </View>
    </ListItem>
  );
}

const _styles = StyleSheet.create({
  badge: {
    backgroundColor: colors.badge,
    height: 20,
    minWidth: 20,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 24,
  },
  inboxItem: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
