const faqContents = [
  {
    title: 'What if I don’t have enough money in my account?',
    content:
      'You need to **Add Money** to your account under the **Payment Methods** section of your profile settings.',
  },
  {
    title: 'How do I add funds to my account?',
    content:
      'First, check to make sure you have a bank or credit card account linked to your ShareSend account under the **Payment Methods** section. Then, select the payment method you wish to use to **Add Money** to your ShareSend account. You will be prompted to type the exact amount you wish to add.',
  },
  {
    title: 'What if I spend more than I have in my account?',
    content:
      'We will notify you immediately that you have insufficient funds and you will be directed to chat with a support agent to resolve the issue.',
  },
];

export default faqContents;
