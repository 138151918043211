import { ComponentProps, useMemo } from 'react';
import { Text as RNText } from 'react-native';

import { colors } from '../constants';
import useThemedStyle from '../hooks/useThemedStyle';

export default function Text({
  style: styleProp,
  ...props
}: ComponentProps<typeof RNText>) {
  const themedStyle = useThemedStyle(styleProp);

  const style = useMemo(() => {
    const fontSize = themedStyle.fontSize || 14;
    const color = themedStyle.color || colors.text;
    const lineHeight = themedStyle.lineHeight || fontSize * 1.5;

    return [themedStyle, { lineHeight, color, fontSize }];
  }, [themedStyle]);

  return <RNText style={style} {...props} />;
}
