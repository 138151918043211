import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { channelCustomTypes } from 'inbox-constants';
import { useEffect, useState } from 'react';
import { View } from 'react-native';

import Image from '../components/Image';
import TabBar from '../components/TabBar';
import { tabs } from '../constants';
import useFakeNewMessageAlert from '../hooks/useFakeNewMessageAlert';
import useTranslatedMessage from '../hooks/useTranslatedMessage';
import { delay, getFirstMessageOfChannelWithCustomType } from '../utils/common';
import { sendbird } from '../utils/sendbird';
import InboxScreen from './InboxScreen';

const Tab = createBottomTabNavigator();

function EmptyScreen() {
  return <View />;
}

export default function HomeTabNavigator() {
  const [unreadCount, setUnreadCount] = useState(0);
  const navigation = useNavigation();

  const sendFakeNewMessageAlert = useFakeNewMessageAlert();

  useEffect(() => {
    sendbird.getTotalUnreadMessageCount().then(setUnreadCount);

    const userEventHandler = new sendbird.UserEventHandler();
    userEventHandler.onTotalUnreadMessageCountUpdated = (count) => {
      setUnreadCount(count);
    };
    sendbird.addUserEventHandler('app', userEventHandler);

    return () => {
      sendbird.removeUserEventHandler('app');
    };
  }, []);

  const getTranslatedMessage = useTranslatedMessage();

  useEffect(() => {
    const channelHandler = new sendbird.ChannelHandler();
    channelHandler.onMessageReceived = async (channel, message) => {
      if (
        channel.customType === channelCustomTypes.promotions &&
        message.message ===
          'Share this special subscription offer with a friend.'
      ) {
        const [firstSalesConciergeMessage] = await Promise.all([
          getFirstMessageOfChannelWithCustomType(
            channelCustomTypes.salesConcierge,
          ),
          delay(1000),
        ]);
        await sendFakeNewMessageAlert({
          title: 'Sales Concierge',
          description: getTranslatedMessage(firstSalesConciergeMessage),
          targetChannelCustomType: channelCustomTypes.salesConcierge,
        });
      } else if (
        channel.customType === channelCustomTypes.notifications2 &&
        message.message ===
          'Check your account balance. Insufficient funds for recent purchase. Click help for support.'
      ) {
        await delay(1000);
        await sendFakeNewMessageAlert({
          title: 'Notifications',
          description: getTranslatedMessage(message),
          targetChannelCustomType: channelCustomTypes.notifications2,
        });
      }
    };
    sendbird.addChannelHandler('GLOBAL_CHANNEL_HANDLER', channelHandler);

    return () => {
      sendbird.removeChannelHandler('GLOBAL_CHANNEL_HANDLER');
    };
  }, [navigation, sendFakeNewMessageAlert, getTranslatedMessage]);

  return (
    <Tab.Navigator tabBar={(props) => <TabBar {...props} />}>
      <Tab.Screen
        name={tabs.inbox}
        component={InboxScreen}
        options={{
          tabBarBadge: unreadCount > 0 ? unreadCount : undefined,
          tabBarIcon: ({ color }) => {
            return (
              <Image
                source={require('../assets/ic-tab-inbox.png')}
                style={{ width: 40, height: 40, tintColor: color }}
              />
            );
          },
        }}
      />
      <Tab.Screen
        name={tabs.people}
        component={EmptyScreen}
        options={{
          tabBarIcon: ({ color }) => {
            return (
              <Image
                source={require('../assets/ic-tab-people.png')}
                style={{ width: 40, height: 40, tintColor: color }}
              />
            );
          },
        }}
      />
      <Tab.Screen
        name={tabs.explore}
        component={EmptyScreen}
        options={{
          tabBarIcon: ({ color }) => (
            <Image
              source={require('../assets/ic-tab-explore.png')}
              style={{ width: 40, height: 40, tintColor: color }}
            />
          ),
        }}
      />
    </Tab.Navigator>
  );
}
