import { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Pressable, StyleProp, ViewStyle } from 'react-native';
import Animated, {
  interpolateColor,
  runOnJS,
  useAnimatedRef,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { BUBBLE_PADDING, core } from '../constants';
import useThemeValues from '../hooks/useThemeValues';
import styles from '../styles';
import ListItemWrapper from './ListItemWrapper';
import Text from './Text';

function Option({
  children,
  onPress,
  style,
}: {
  children: string;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
}) {
  const theme = useThemeValues();
  const color = useSharedValue(0);
  const elevation = useSharedValue(1);
  const animatedStyle = useAnimatedStyle(() => ({
    backgroundColor: withTiming(
      interpolateColor(
        color.value,
        [0, 1],
        [
          theme.suggestedReplyBackgroundDefault,
          theme.suggestedReplyBackgroundHover,
        ],
      ),
      { duration: 100 },
    ),
    shadowRadius: elevation.value * 8,
    shadowOffset: { width: 0, height: elevation.value * 4 },
  }));
  return (
    <Pressable
      onPress={onPress}
      onHoverIn={() => {
        color.value = 1;
      }}
      onHoverOut={() => {
        color.value = 0;
      }}
      onPressIn={() => {
        elevation.value = withTiming(0, { duration: 100 });
      }}
      onPressOut={() => {
        elevation.value = withTiming(1, { duration: 100 });
      }}
      style={style}
    >
      <Animated.View style={[componentStyles.option, animatedStyle]}>
        <Text style={[styles.textSmedium, componentStyles.optionText]}>
          {children}
        </Text>
      </Animated.View>
    </Pressable>
  );
}

export default function BotResponseOptions({
  botResponseOptions: options,
  sendResponseToBot,
  style,
}: {
  botResponseOptions: string[];
  sendResponseToBot: (response: string) => void;
  style?: StyleProp<ViewStyle>;
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const ref = useAnimatedRef<Animated.View>();
  const opacity = useSharedValue(1);
  const marginTop = useDerivedValue(() => opacity.value * 24);

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
    marginTop: marginTop.value,
  }));

  const onSelectOption = (option) => {
    opacity.value = withTiming(0, { duration: 200 }, (finished) => {
      if (finished && sendResponseToBot) {
        runOnJS(sendResponseToBot)(option);
      }
    });
    setSelectedOption(option);
  };

  return (
    <ListItemWrapper>
      <Animated.View
        ref={ref}
        style={[componentStyles.container, animatedStyle, style]}
      >
        <Text
          style={[styles.textXSmall, styles.textBold, componentStyles.title]}
        >
          Suggested reply
        </Text>
        {options.map((option, index) => {
          return (
            <Option
              key={option}
              style={{ marginTop: index > 0 ? 6 : 0 }}
              onPress={() => {
                if (selectedOption == null) {
                  onSelectOption(option);
                }
              }}
            >
              {option}
            </Option>
          );
        })}
      </Animated.View>
    </ListItemWrapper>
  );
}

const componentStyles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingEnd: 12,
    overflow: 'hidden',
    paddingBottom: 12,
    minHeight: 0,
    marginStart: 64,
  },
  title: {
    color: core.content[3],
    paddingHorizontal: BUBBLE_PADDING,
    marginBottom: 4,
  },
  option: {
    borderRadius: 18,
    paddingHorizontal: BUBBLE_PADDING,
    paddingVertical: BUBBLE_PADDING / 2,
    shadowOffset: { width: 0, height: 4 },
    shadowColor: 'rgba(33, 33, 33, 0.16)',
  },
  optionText: {
    color: '@suggestedReplyText',
    fontWeight: '500',
  },
});
