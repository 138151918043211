import { ViewProps } from 'react-native';
import * as Progress from 'react-native-progress';

import useThemeValues from '../hooks/useThemeValues';

export default function CircleProgress({
  size,
  color,
  ...props
}: { size: number; color?: string } & ViewProps) {
  const theme = useThemeValues();

  return (
    <Progress.Circle
      size={size}
      indeterminate={true}
      color={color || theme.accent}
      borderWidth={size / 8}
      {...props}
    />
  );
}
