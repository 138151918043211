import Constants from 'expo-constants';
import { botUserIds } from 'inbox-constants';

export * from './colors';
export * from './localImageMap';

export const STORAGE_KEY_USER = 'savedUser';
export const APP_ID = Constants.manifest?.extra?.APP_ID;
export const DEFAULT_MALE_PROFILE_URL =
  'https://dxstmhyqfqr1o.cloudfront.net/inbox-demo/avatars/alex.png';
export const DEFAULT_FEMALE_PROFILE_URL =
  'https://file-us-3.sendbird.com/61b3540cce934d1cbf5eb1e980f63d15.jpg';

export const maskOptions = {
  precision: 0,
  separator: '.',
  delimiter: ',',
  unit: '$',
  suffixUnit: '',
};

export const tabs = {
  inbox: 'Inbox',
  people: 'People',
  explore: 'Explore',
};

export const TABLET_LANDSCAPE_WIDTH = 1024;
export const BUBBLE_PADDING = 12;

export const fakeUserIds = {
  [botUserIds.angela]: 'lemonsand',
  [botUserIds.daniel]: 'ddale7',
  [botUserIds.grace]: 'gracedli',
  [botUserIds.robin]: 'Robin194',
};

export const languageNames = {
  en: 'English',
  ko: '한국어',
  es: 'Español',
};

export const asyncStorageKeys = {
  savedUser: 'savedUser',
  theme: 'theme',
};
